import {useCallback, useMemo, useState} from "react";
import { Button, Container, Layout, Selector } from "components/common";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DateInput } from "components/common/DateInput";
import { countriesMap } from "../constants";

const countriesKeyList = Object.keys(countriesMap);

export const Search = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const country = searchParams.get("country") || "";
  const startDate =
    Number(searchParams.get("startDate")) || new Date().getTime();
  const endDate =
    Number(searchParams.get("endDate")) ||
    new Date().getTime() + 7 * 24 * 60 * 60 * 1000;

  const [formData, setFormData] = useState({
    country,
    startDate,
    endDate,
  });

  const onChangeInfo = useCallback(
    (field) => (value) => {
      setFormData((prevInfo) => ({ ...prevInfo, [field]: value }));
    },
    []
  );

  const bottomSlot = (
    <Button
      className="w-full"
      onClick={() => {
        const query = new URLSearchParams(formData);
        navigate("/search-list?" + query);
      }}
    >
      Search
    </Button>
  );

  const countyList = useMemo(() => (countriesKeyList.map((countryKey) => ({
    value: countryKey,
    label: `${countriesMap[countryKey]?.emoji} ${countriesMap[countryKey]?.name}`,
  }))), [])

  return (
    <>
      <Layout
        className="relative"
        actionButtonSlot={bottomSlot}
        title="Search Trips"
        showBack
      >
        <Container className="pt-4">
          <div className="w-full pt-2 flex flex-col gap-4">
            <Selector
              label="Destination"
              value={formData.country}
              onChange={onChangeInfo("country")}
              id="country-selector"
              options={countyList}
              placeholder="Choose country"
            />
            <DateInput
              id="startdate-123"
              label="Start Date"
              value={formData.startDate}
              onChange={onChangeInfo("startDate")}
            />
            <DateInput
              id="enddate-123"
              label="End Date"
              value={formData.endDate}
              onChange={onChangeInfo("endDate")}
            />
          </div>
        </Container>
      </Layout>
    </>
  );
};
