import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import {
  Button,
  DateInput,
  Input,
  Selector,
  Textarea,
} from "components/common";
import { countriesMap } from "constants";
import { GoogleGenerativeAI } from "@google/generative-ai";

const countriesKeyList = Object.keys(countriesMap);

const PROMPT = `
You are an AI assistant specialized in parsing travel plans. Your task is to extract specific information from the given text and format it as JSON. Follow these guidelines:

1. Title:
   - Create a short, lively title of maximum 6 words
   - Use simple, casual language
   - Use all lowercase letters, only first word big letter 
   - May include an appropriate emoji
   - Capture the essence and vibe of the text
   - Choose only first part of trip in title if many countries exist

2. Birthdate:
   - Extract the age if mentioned
   - If not mentioned, randomly select an age between 25-29
   - Return birthdate with format as a miliseconds timestamp
   - MAXIMUM TIMESTAMP IS 1155664062246

3. Dates:
   - Extract start and end dates if mentioned
   - If dates are missing, estimate based on the current date
   - Dates should not be older than today
   - Maximum duration should be 3 days if exact dates aren't provided
   - Format both startDate and endDate as timestamps miliseconds
   - MINIMUM TIMESTAMP IS 1723742684844

4. Country:
   - Identify the destination country
   - Choose only first country if many exist
   - Provide the corresponding two-letter country code (e.g., AL for Albania)

5. Message:
   - Create a short, lively message of maximum 6-8 words with instagram @
   - Simple example: If you want join, send me message to @Instagram
   - Extract Instagram username if mentioned
   - If not mentioned, leave it as an empty string
   - Use simple, casual language
   - Use all lowercase letters, only first word big letter 

6. Introduction:
   - Create a short, lively introduction of maximum few phrases
   - Use simple, casual language
   - Text should be live, lovely and easy
   - Create interesting information
   - Don't use information about plans for travel

7. Instagram:
   - Extract Instagram account 
   - If not exist, leave empty
   - Don't use @ symbol

8. TikTok:
   - Extract TikTok account 
   - If not exist, leave empty
   - Don't use @ symbol

Analyze the given text and return the extracted information in the following JSON format and can parse with JSON.parse(result) so don't need to add md format to result:

{"success": true,"data": {"title": "","birthdate": "","startDate": "","endDate": "","country": "","message": "", "introduction": "", "instagram": "", "tiktok": "" }}

DO NOT INCLUDE MD FORMAT. SEND OBJECT AS A STRING DIRECTLY

Text: `;

const generateContent = async (apiKey, text) => {
  if (!apiKey) {
    throw new Error("API key is required");
  }

  const genAI = new GoogleGenerativeAI(apiKey);
  const model = genAI.getGenerativeModel({ model: "gemini-pro" });

  const result = await model.generateContent(`${PROMPT} ${text}`);
  const generatedResponse = await result.response;
  const textResult = generatedResponse.text();
  console.log("Generated result: ", JSON.parse(textResult));
  return JSON.parse(textResult);
};

const fetchList = async (secret, page) =>
  fetch(`/api/public/bot?secret=${secret}&page=${page}`)
    .then((res) => res.json())
    .then((res) => {
      if (res.success) return res;
      throw new Error(res.message);
    });

const deleteBot = (botId) =>
  fetch("/api/public/bot/delete", {
    method: "POST",
    body: JSON.stringify({ botId }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success) return res;
      throw new Error(res.message);
    });

const hideBot = async (secret, groupId) =>
  fetch(`/api/public/bot/deactivate?secret=${secret}&groupId=${groupId}`)
    .then((res) => res.json())
    .then((res) => {
      if (res.success) return res;
      throw new Error(res.message);
    });

const addBot = (info) =>
  fetch("/api/public/bot/add", {
    method: "POST",
    body: JSON.stringify(info),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success) return res;
      throw new Error(res.message);
    });

const Item = (props) => {
  const { user, group, raw, apiKey, refetchList } = props;
  const botId = props._id;
  const [info, setInfo] = useState({
    user: {
      name: user?.name,
      birthdate: new Date(
        new Date().setFullYear(new Date().getFullYear() - 18)
      ).getTime(),
      image: user?.images?.[0],
      message: "",
      introduction: "",
      tiktok: "",
      instagram: "",
    },
    group: {
      title: group?.title || "",
      description: group?.description || "",
      image: group?.images?.[0],
      country: "",
      startDate: "",
      endDate: "",
    },
    botId,
  });

  const setGroupInfo = (field) => (value) => {
    setInfo((prevInfo) => ({
      ...prevInfo,
      group: { ...prevInfo.group, [field]: value },
    }));
  };

  const setUserInfo = (field) => (value) => {
    setInfo((prevInfo) => ({
      ...prevInfo,
      user: { ...prevInfo.user, [field]: value },
    }));
  };

  const { mutate: onDeleteBot, isPending: isPendingDelete } = useMutation({
    mutationFn: () => deleteBot(botId),
    onSuccess: (res) => {
      if (res.success) return refetchList();
      console.log("ERROR", res);
    },
  });

  const { mutate: onAddBot, isPending: isPendingAdd } = useMutation({
    mutationFn: () => addBot(info),
    onSuccess: (res) => {
      if (res.success) return refetchList();
      console.log("ERROR", res);
    },
  });

  const {
    data: generatedData,
    refetch: onGenerate,
    isFetching: isFetchingGenerate,
  } = useQuery({
    queryKey: ["GENERATE", raw.postUrl],
    queryFn: () => generateContent(apiKey, info.group.description),
    enabled: false,
    retry: false,
  });

  useEffect(() => {
    if (generatedData?.success) {
      setInfo((prevInfo) => ({
        ...prevInfo,
        user: {
          ...prevInfo.user,
          birthdate: +generatedData.data.birthdate,
          message: generatedData.data.message,
          introduction: generatedData.data.introduction,
          instagram: generatedData.data.instagram,
          tiktok: generatedData.data.tiktok,
        },
        group: {
          ...prevInfo.group,
          title: generatedData.data.title,
          startDate: +generatedData.data.startDate,
          endDate: +generatedData.data.endDate,
          country: generatedData.data.country,
        },
      }));
    }
  }, [generatedData?.success]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border-2 p-2 rounded-xl">
      <div className="flex flex-col gap-2">
        <div className="uppercase font-bold">user</div>
        <div className="flex flex-wrap gap-2">
          {(user.images || []).map((el) => (
            <div className="relative" key={el}>
              {info.user.image === el && (
                <div className="absolute top-1 right-2 text-xl">✅</div>
              )}
              <img
                src={el}
                className={`w-[200px] h-[200px] cursor-pointer ${
                  info.user.image === el
                    ? "border-2 border-green-500"
                    : "border-2"
                }`}
                onClick={() => setUserInfo("image")(el)}
              />
            </div>
          ))}
        </div>
        <Input
          label="Name"
          value={info.user.name}
          onChange={setUserInfo("name")}
        />
        <DateInput
          label="Birthdate"
          value={info.user.birthdate}
          id="birthdate-123"
          onChange={setUserInfo("birthdate")}
          maxDate={
            new Date(new Date().setFullYear(new Date().getFullYear() - 18))
          }
        />
        <Input
          label="First Message"
          value={info.user.message}
          onChange={setUserInfo("message")}
        />
        <Textarea
          rows={3}
          label="Introduction"
          type="text"
          onChange={setUserInfo("introduction")}
          value={info.user.introduction}
        />
        <Input
          label="Instagram"
          value={info.user.instagram}
          onChange={setUserInfo("instagram")}
        />
        <Input
          label="TikTok"
          value={info.user.tiktok}
          onChange={setUserInfo("tiktok")}
        />
      </div>
      <div className="flex flex-col gap-2">
        <div className="uppercase font-bold">group</div>
        <div className="flex flex-wrap gap-2">
          {(group.images || []).map((el) => (
            <div className="relative" key={el}>
              {info.group.image === el && (
                <div className="absolute top-1 right-2 text-xl">✅</div>
              )}
              <img
                src={el}
                className={`w-[200px] h-[200px] cursor-pointer ${
                  info.group.image === el
                    ? "border-2 border-green-500"
                    : "border-2"
                }`}
                onClick={() => setGroupInfo("image")(el)}
              />
            </div>
          ))}
        </div>
        <Input
          label="Title"
          value={info.group.title}
          onChange={setGroupInfo("title")}
        />
        <div className="flex gap-4 w-full">
          <DateInput
            label="Start Date"
            value={info.group.startDate}
            id="start-date"
            onChange={setGroupInfo("startDate")}
            minDate={new Date()}
          />
          <DateInput
            label="End Date"
            value={info.group.endDate}
            id="end-date"
            onChange={setGroupInfo("endDate")}
            minDate={info.group?.startDate || new Date()}
          />
        </div>
        <Textarea
          rows={10}
          label="Description"
          type="text"
          onChange={setGroupInfo("description")}
          value={info.group.description}
        />
        <Selector
          label="Country"
          value={info.group.country}
          onChange={setGroupInfo("country")}
          id="country-selector"
          options={countriesKeyList.map((countryKey) => ({
            value: countryKey,
            label: `${countriesMap[countryKey]?.emoji} ${countriesMap[countryKey]?.name}`,
          }))}
        />
        <div className="flex w-full gap-2">
          <Button
            className="w-full bg-blue-500"
            isLoading={isFetchingGenerate}
            onClick={() => onGenerate()}
          >
            Generate
          </Button>
          <Button
            className="w-full bg-red-500"
            onClick={() => onDeleteBot()}
            isLoading={isPendingDelete}
          >
            Delete
          </Button>
          <Button
            className="w-full bg-green-500"
            onClick={() => onAddBot()}
            isLoading={isPendingAdd}
          >
            Add
          </Button>
        </div>
      </div>
    </div>
  );
};

export const Bot = () => {
  const [page, setPage] = useState(1);
  const [searchParams] = useSearchParams();
  const secret = searchParams.get("secret") || "";
  const apiKey = searchParams.get("apikey") || "";

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["GET_BOT", page, secret],
    queryFn: () => fetchList(secret, page),
    enabled: Boolean(secret),
  });

  const { mutate: onHideBot, isFetching: isFetchingHide } = useMutation({
    mutationFn: () => hideBot(secret, groupId),
    onSuccess: (res) => {
      if (res.success) return setGroupId("");
      console.log("ERROR", res);
    },
  });

  const [groupId, setGroupId] = useState("");

  if (!secret) return <div>Restrict</div>;
  if (isLoading) return <div>Loading</div>;

  return (
    <>
      <div className="gap-4 flex">
        <Input value={groupId} onChange={(v) => setGroupId(v)} />
        <Button
          className="bg-red-500"
          onClick={onHideBot}
          isLoading={isFetchingHide}
        >
          Hide Group
        </Button>
      </div>
      <div className="flex flex-col w-full gap-20 p-4">
        {(data?.data?.list || []).map((el) => (
          <Item
            key={el.raw.postUrl}
            apiKey={apiKey}
            refetchList={refetch}
            {...el}
          />
        ))}
      </div>
    </>
  );
};
