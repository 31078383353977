import React, { useState, useRef, useEffect } from "react";
import { format } from "date-fns";

export const DateInput = ({
  label,
  value,
  onChange,
  id,
  placeholder = "Select a date",
  minDate,
  maxDate,
}) => {
  const [displayValue, setDisplayValue] = useState(
    value ? format(new Date(value), "MMM dd yyyy") : ""
  );

  useEffect(() => {
    if (value) {
      setDisplayValue(format(new Date(value), "MMM dd yyyy"));
    }
  }, [value]);

  const dateInputRef = useRef(null);

  const handleDateChange = (e) => {
    const date = new Date(e.target.value);
    setDisplayValue(format(date, "MMM dd yyyy"));
    onChange(date.getTime());
  };

  const handleClick = () => {
    dateInputRef.current.focus();
    dateInputRef.current.showPicker();
  };

  return (
    <div className="relative w-full" onClick={handleClick}>
      {label && (
        <label
          className="absolute font-semibold text-main break-words cursor-default pointer-events-none text-xs pl-[12px] pt-[6px] z-10"
          htmlFor={id}
          id={`${id}-label`}
        >
          {label}
        </label>
      )}
      <div className="relative">
        <input
          ref={dateInputRef}
          type="date"
          className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
          onChange={handleDateChange}
          min={minDate ? format(new Date(minDate), "yyyy-MM-dd") : undefined}
          max={maxDate ? format(new Date(maxDate), "yyyy-MM-dd") : undefined}
        />
        <div
          className={`appearance-none resize-none box-border rounded-md text-[18px] w-full text-black block text-left px-[12px] border-[1px] border-line bg-b-main min-h-[60px] hover:border-acent focus:outline-none ${
            label ? "pt-[24px]" : ""
          } cursor-pointer`}
          id={id}
        >
          {displayValue || placeholder}
        </div>
      </div>
    </div>
  );
};
