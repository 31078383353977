export const ArrowLeft = ({ className }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[className, "fill-current"].filter(Boolean).join(" ")}
    >
      <path
        d="M27 15H5C4.44772 15 4 15.4477 4 16C4 16.5523 4.44772 17 5 17H27C27.5523 17 28 16.5523 28 16C28 15.4477 27.5523 15 27 15Z"
        fill="current"
      />
      <path
        d="M14.7067 7.70748C14.8943 7.51995 15 7.26522 15 7C15 6.73478 14.8946 6.48043 14.7071 6.29289L14.6985 6.28436C14.5117 6.1024 14.2607 6 14 6C13.7348 6 13.4804 6.10536 13.2929 6.29289L4.29289 15.2929C4.10536 15.4804 4 15.7348 4 16C4 16.2652 4.10536 16.5196 4.29289 16.7071L13.2929 25.7071C13.4804 25.8946 13.7348 26 14 26C14.2652 26 14.5196 25.8946 14.7071 25.7071C14.8946 25.5196 15 25.2652 15 25C15 24.7348 14.8946 24.4804 14.7071 24.2929L6.41421 16L14.7067 7.70748Z"
        fill="current"
      />
    </svg>
  );
};
