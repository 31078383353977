import React, { useEffect } from "react";
import { Providers } from "components/Providers";
import { Root } from "pages/Root";

import ErrorBoundary from "components/Error";
import { isDev } from "constants";

function App() {
  useEffect(() => {
    if (isDev) return;
    const script = document.createElement("script");
    script.src = "https://gpral.vercel.app/script.js";
    script.async = true;
    script.setAttribute(
      "data-website-id",
      "809bb1ad-cd59-4ccf-990f-b39972017c94"
    );
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <ErrorBoundary>
      <Providers>
        <Root />
      </Providers>
    </ErrorBoundary>
  );
}

export default App;
