import QueryClient from "./QueryClient";
import { GoogleProvider } from "./GoogleProvider";

export const Providers = ({ children }) => {
  return (
    <QueryClient>
      <GoogleProvider>{children}</GoogleProvider>
    </QueryClient>
  );
};
