export const Input = ({
  label,
  value,
  onChange,
  id,
  placeholder,
  type = "text",
  maxLength = 1000,
}) => {
  return (
    <div className="relative">
      {label && (
        <label
          className="absolute font-semibold text-main break-words cursor-default pointer-events-none text-xs pl-[12px] pt-[6px] z-10"
          forhtml={id}
          id={`${id}-label`}
        >
          {label}
        </label>
      )}
      <div className="relative">
        <input
          maxLength={maxLength}
          placeholder={placeholder}
          className={`appearance-none resize-none box-border rounded-md text-[18px] w-full text-black block text-left px-[12px] border-[1px] border-line bg-b-main min-h-[60px] hover:border-acent focus:outline-none placeholder:text-placeholder ${
            label ? "pt-[18px]" : ""
          }`}
          id={id}
          type={type}
          aria-invalid="false"
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
    </div>
  );
};
