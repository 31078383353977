import { Container } from "components/common";
import { Layout } from "components/common/Layout";

export const Pp = () => {
  return (
    <Layout>
      <div className="flex flex-col">
        <div className="bg-gray-200 pt-32 pb-20 md:pb-32 md:pt-40" id="#hero">
          <Container>
            <div className="mx-auto text-center shrink-0 space-y-3 pb-2 max-w-2xl">
              <h1 className="tracking-tight text-3xl md:text-5xl font-bold">
                Privacy Policy
              </h1>
              <h2 className="text-secondary text-md font-semibold sm:text-xl">
                We value your safety, privacy and data rights
              </h2>
            </div>
          </Container>
        </div>
        <div className="px-4">
          <Container>
            <div className="prose max-w-full py-20 text-justify">
              <h3 className="">General</h3>
              <p>
                This Privacy Policy describes Our policies and procedures on the
                collection, use, and disclosure of Your information when You use
                the Service and tells You about Your privacy rights and how the
                law protects You.
              </p>

              <p>
                We use Your Personal data to provide and improve the Service. By
                using the Service, You agree to the collection and use of
                information in accordance with this Privacy Policy.
              </p>

              <p>
                You can sign up with your Google account so your Talivia's
                account username will be prefilled with your name and your
                public profile picture.{" "}
                <a href="https://developers.google.com/terms/api-services-user-data-policy">
                  Google API Services User Data Policy
                </a>
              </p>

              <h3>Interpretation and Definitions</h3>
              <p>
                <strong>Interpretation</strong>
              </p>
              <p>
                The words of which the initial letter is capitalized have
                meanings defined under the following conditions. The following
                definitions shall have the same meaning regardless of whether
                they appear in singular or in plural.
              </p>

              <p>
                <strong>Definitions</strong>
              </p>
              <p>For the purposes of this Privacy Policy:</p>

              <ul>
                <li>
                  <strong>Account</strong> means a unique account created for
                  You to access our Service or parts of our Service.
                </li>
                <li>
                  <strong>Company</strong> (referred to as either "the Company",
                  "We", "Us" or "Our" in this Agreement) refers to Talivia,
                  Newry street, Fitzroy North.
                </li>
                <li>
                  <strong>Cookies</strong> are small files that are placed on
                  Your computer, mobile device, or any other device by a
                  website, containing the details of Your browsing history on
                  that website among its many uses.
                </li>
                <li>
                  <strong>Country</strong> refers to: Thailand
                </li>
                <li>
                  <strong>Device</strong> means any device that can access the
                  Service such as a computer, a cellphone, or a digital tablet.
                </li>
                <li>
                  <strong>Personal Data</strong> is any information that relates
                  to an identified or identifiable individual.
                </li>
                <li>
                  <strong>Service</strong> refers to the Website.
                </li>
                <li>
                  <strong>Service Provider</strong> means any natural or legal
                  person who processes the data on behalf of the Company. It
                  refers to third-party companies or individuals employed by the
                  Company to facilitate the Service, to provide the Service on
                  behalf of the Company, to perform services related to the
                  Service or to assist the Company in analyzing how the Service
                  is used.
                </li>
                <li>
                  <strong>Third-party Social Media Service</strong> refers to
                  any website or any social network website through which a User
                  can log in or create an account to use the Service.
                </li>
                <li>
                  <strong>Usage Data</strong> refers to data collected
                  automatically, either generated by the use of the Service or
                  from the Service infrastructure itself (for example, the
                  duration of a page visit).
                </li>
                <li>
                  <strong>Website</strong> refers to Talivia, accessible from
                  https://talivia.com
                </li>
                <li>
                  <strong>You</strong> means the individual accessing or using
                  the Service, or the company, or other legal entity on behalf
                  of which such individual is accessing or using the Service, as
                  applicable.
                </li>
              </ul>

              <h3>Collecting and Using Your Personal Data</h3>
              <p>
                <strong>Types of Data Collected</strong>
              </p>

              <p>
                <strong>Personal Data</strong>
              </p>
              <p>
                While using Our Service, We may ask You to provide Us with
                certain personally identifiable information that can be used to
                contact or identify You. Personally identifiable information may
                include, but is not limited to:
              </p>

              <ul>
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Usage Data</li>
              </ul>

              <p>
                <strong>Usage Data</strong>
              </p>
              <p>
                Usage Data is collected automatically when using the Service.
              </p>
              <p>
                Usage Data may include information such as Your Device's
                Internet Protocol address (e.g. IP address), browser type,
                browser version, the pages of our Service that You visit, the
                time and date of Your visit, the time spent on those pages,
                unique device identifiers and other diagnostic data.
              </p>
              <p>
                When You access the Service by or through a mobile device, We
                may collect certain information automatically, including, but
                not limited to, the type of mobile device You use, Your mobile
                device unique ID, the IP address of Your mobile device, Your
                mobile operating system, the type of mobile Internet browser You
                use, unique device identifiers and other diagnostic data.
              </p>
              <p>
                We may also collect information that Your browser sends whenever
                You visit our Service or when You access the Service by or
                through a mobile device.
              </p>

              <h3>Information from Third-Party Social Media Services</h3>
              <p>
                The Company allows You to create an account and log in to use
                the Service through the following Third-party Social Media
                Services:
              </p>
              <ul>
                <li>
                  Facebook. If you use sign in with Facebook account. You can
                  delete your data by clicking "Profile" page, then click right
                  top corner and in output menu choose "Delete profile"
                </li>
                <li>Google</li>
              </ul>
              <p>
                If You decide to register through or otherwise grant us access
                to a Third-Party Social Media Service, We may collect Personal
                data that is already associated with Your Third-Party Social
                Media Service's account, such as Your name, Your email address,
                Your activities or Your contact list associated with that
                account
              </p>
              <p>
                You may also have the option of sharing additional information
                with the Company through Your Third-Party Social Media Service's
                account. If You choose to provide such information and Personal
                Data, during registration or otherwise, You are giving the
                Company permission to use, share, and store it in a manner
                consistent with this Privacy Policy.
              </p>

              <h3>Tracking Technologies and Cookies</h3>
              <p>
                We use Cookies and similar tracking technologies to track the
                activity on Our Service and store certain information. Tracking
                technologies used are beacons, tags, and scripts to collect and
                track information and to improve and analyze Our Service. The
                technologies We use may include:
              </p>

              <ul>
                <li>
                  <strong>Cookies or Browser Cookies:</strong> A cookie is a
                  small file placed on Your Device. You can instruct Your
                  browser to refuse all Cookies or to indicate when a Cookie is
                  being sent. However, if You do not accept Cookies, You may not
                  be able to use some parts of our Service. Unless you have
                  adjusted Your browser setting so that it will refuse Cookies,
                  our Service may use Cookies.
                </li>
                <li>
                  <strong>Flash Cookies:</strong> Certain features of our
                  Service may use local stored objects (or Flash Cookies) to
                  collect and store information about Your preferences or Your
                  activity on our Service. Flash Cookies are not managed by the
                  same browser settings as those used for Browser Cookies.
                </li>
                <li>
                  <strong>Web Beacons:</strong> Certain sections of our Service
                  and our emails may contain small electronic files known as web
                  beacons that permit the Company to count users who have
                  visited those pages or opened an email and for other related
                  website statistics.
                </li>
              </ul>

              <p>
                Cookies can be "Persistent" or "Session" Cookies. Persistent
                Cookies remain on Your personal computer or mobile device when
                You go offline, while Session Cookies are deleted as soon as You
                close Your web browser.
              </p>

              <h4>
                We use both Session and Persistent Cookies for the purposes set
                out below:
              </h4>

              <ul>
                <li>
                  <strong>Necessary / Essential Cookies:</strong> Type: Session
                  Cookies. Administered by: Us. Purpose: These Cookies are
                  essential to provide You with services available through the
                  Website and to enable You to use some of its features.
                </li>
                <li>
                  <strong>Cookies Policy / Notice Acceptance Cookies:</strong>{" "}
                  Type: Persistent Cookies. Administered by: Us. Purpose: These
                  Cookies identify if users have accepted the use of cookies on
                  the Website.
                </li>
                <li>
                  <strong>Functionality Cookies:</strong> Type: Persistent
                  Cookies. Administered by: Us. Purpose: These Cookies allow us
                  to remember choices You make when You use the Website.
                </li>
                <li>
                  <strong>Tracking and Performance Cookies:</strong> Type:
                  Persistent Cookies. Administered by: Third-Parties. Purpose:
                  These Cookies are used to track information about traffic to
                  the Website and how users use the Website.
                </li>
              </ul>

              <p>
                For more information about the cookies we use and your choices
                regarding cookies, please visit our Cookies Policy or the
                Cookies section of our Privacy Policy.
              </p>

              <h3>Use of Your Personal Data</h3>
              <p>
                The Company may use Personal Data for the following purposes:
              </p>

              <ul>
                <li>
                  To provide and maintain our Service, including to monitor the
                  usage of our Service.
                </li>
                <li>
                  To manage Your Account: to manage Your registration as a user
                  of the Service. The Personal Data You provide can give You
                  access to different functionalities of the Service that are
                  available to You as a registered user.
                </li>
                <li>
                  For the performance of a contract: the development, compliance
                  and undertaking of the purchase contract for the products,
                  items or services You have purchased or of any other contract
                  with Us through the Service.
                </li>
                <li>
                  To contact You: To contact You by email, telephone calls, SMS,
                  or other equivalent forms of electronic communication, such as
                  a mobile application's push notifications regarding updates or
                  informative communications related to the functionalities,
                  products or contracted services, including the security
                  updates, when necessary or reasonable for their
                  implementation.
                </li>
                <li>
                  To provide You with news, special offers and general
                  information about other goods, services and events which we
                  offer that are similar to those that you have already
                  purchased or enquired about unless You have opted not to
                  receive such information.
                </li>
                <li>
                  To manage Your requests: To attend and manage Your requests to
                  Us.
                </li>
                <li>
                  For business transfers: We may use Your information to
                  evaluate or conduct a merger, divestiture, restructuring,
                  reorganization, dissolution, or other sale or transfer of some
                  or all of Our assets, whether as a going concern or as part of
                  bankruptcy, liquidation, or similar proceeding, in which
                  Personal Data held by Us about our Service users is among the
                  assets transferred.
                </li>
                <li>
                  For other purposes: We may use Your information for other
                  purposes, such as data analysis, identifying usage trends,
                  determining the effectiveness of our promotional campaigns and
                  to evaluate and improve our Service, products, services,
                  marketing and your experience.
                </li>
              </ul>

              <p>
                We may share Your personal information in the following
                situations:
              </p>

              <ul>
                <li>
                  <strong>With Service Providers:</strong> We may share Your
                  personal information with Service Providers to monitor and
                  analyze the use of our Service, for payment processing, to
                  contact You.
                </li>
                <li>
                  <strong>For business transfers:</strong> We may share or
                  transfer Your personal information in connection with, or
                  during negotiations of, any merger, sale of Company assets,
                  financing, or acquisition of all or a portion of Our business
                  to another company.
                </li>
                <li>
                  <strong>With Affiliates:</strong> We may share Your
                  information with Our affiliates, in which case we will require
                  those affiliates to honor this Privacy Policy. Affiliates
                  include Our parent company and any other subsidiaries, joint
                  venture partners or other companies that We control or that
                  are under common control with Us.
                </li>
                <li>
                  <strong>With business partners:</strong> We may share Your
                  information with Our business partners to offer You certain
                  products, services or promotions.
                </li>
                <li>
                  <strong>With other users:</strong> when You share personal
                  information or otherwise interact in the public areas with
                  other users, such information may be viewed by all users and
                  may be publicly distributed outside. If You interact with
                  other users or register through a Third-Party Social Media
                  Service, Your contacts on the Third-Party Social Media Service
                  may see Your name, profile, pictures and description of Your
                  activity. Similarly, other users will be able to view
                  descriptions of Your activity, communicate with You and view
                  Your profile.
                </li>
                <li>
                  <strong>With Your consent:</strong> We may disclose Your
                  personal information for any other purpose with Your consent.
                </li>
              </ul>

              <h3>Retention of Your Personal Data</h3>
              <p>
                The Company will retain Your Personal Data only for as long as
                is necessary for the purposes set out in this Privacy Policy. We
                will retain and use Your Personal Data to the extent necessary
                to comply with our legal obligations, resolve disputes, and
                enforce our legal agreements and policies.
              </p>

              <p>
                The Company will also retain Usage Data for internal analysis
                purposes. Usage Data is generally retained for a shorter period
                of time, except when this data is used to strengthen the
                security or to improve the functionality of Our Service, or We
                are legally obligated to retain this data for longer time
                periods.
              </p>

              <h3>Transfer of Your Personal Data</h3>
              <p>
                Your information, including Personal Data, is processed at the
                Company's operating offices and in any other places where the
                parties involved in the processing are located. It means that
                this information may be transferred to — and maintained on —
                computers located outside of Your state, province, country or
                other governmental jurisdiction where the data protection laws
                may differ than those from Your jurisdiction.
              </p>

              <p>
                Your consent to this Privacy Policy followed by Your submission
                of such information represents Your agreement to that transfer.
              </p>

              <p>
                The Company will take all steps reasonably necessary to ensure
                that Your data is treated securely and in accordance with this
                Privacy Policy and no transfer of Your Personal Data will take
                place to an organization or a country unless there are adequate
                controls in place including the security of Your data and other
                personal information.
              </p>

              <h3>Disclosure of Your Personal Data</h3>

              <h4>Business Transactions</h4>
              <p>
                If the Company is involved in a merger, acquisition or asset
                sale, Your Personal Data may be transferred. We will provide
                notice before Your Personal Data is transferred and becomes
                subject to a different Privacy Policy.
              </p>

              <h4>Law enforcement</h4>
              <p>
                Under certain circumstances, the Company may be required to
                disclose Your Personal Data if required to do so by law or in
                response to valid requests by public authorities (e.g. a court
                or a government agency).
              </p>

              <h4>Other legal requirements</h4>
              <p>
                The Company may disclose Your Personal Data in the good faith
                belief that such action is necessary to:
              </p>

              <ul>
                <li>Comply with a legal obligation</li>
                <li>
                  Protect and defend the rights or property of the Company
                </li>
                <li>
                  Prevent or investigate possible wrongdoing in connection with
                  the Service
                </li>
                <li>
                  Protect the personal safety of Users of the Service or the
                  public
                </li>
                <li>Protect against legal liability</li>
              </ul>

              <h3>Data Deletion</h3>
              <p>
                You have the right to delete all of your personal data through
                our website from the general settings page by removing your
                account at any time. If you're unable to, please contact us, and
                we will delete everything related to you and your account.
              </p>

              <h3>
                Detailed Information on the Processing of Your Personal Data
              </h3>
              <p>
                The Service Providers We use may have access to Your Personal
                Data. These third-party vendors collect, store, use, process,
                and transfer information about Your activity on Our Service in
                accordance with their Privacy Policies.
              </p>

              <h3>Email Marketing</h3>
              <p>
                We may use Your Personal Data to contact You with newsletters,
                marketing, or promotional materials and other information that
                may be of interest to You. You may opt-out of receiving any, or
                all, of these communications from Us by following the
                unsubscribe link or instructions provided in any email We send
                or by contacting Us.
              </p>

              <p>
                We may use Email Marketing Service Providers to manage and send
                emails to You.
              </p>

              <h3>Payments</h3>
              <p>
                We may provide paid products and/or services within the Service.
                In that case, we may use third-party services for payment
                processing (e.g., payment processors).
              </p>

              <p>
                We will not store or collect Your payment card details. That
                information is provided directly to Our third-party payment
                processors whose use of Your personal information is governed by
                their Privacy Policy. These payment processors adhere to the
                standards set by PCI-DSS as managed by the PCI Security
                Standards Council, which is a joint effort of brands like Visa,
                Mastercard, American Express, and Discover. PCI-DSS requirements
                help ensure the secure handling of payment information.
              </p>

              <h4>Stripe</h4>
              <p>
                Their Privacy Policy can be viewed at{" "}
                <a href="https://stripe.com/us/privacy" target="_blank">
                  https://stripe.com/us/privacy
                </a>
              </p>

              <h3>Changes to this Privacy Policy</h3>
              <p>
                We may update Our Privacy Policy from time to time. We will
                notify You of any changes by posting the new Privacy Policy on
                this page.
              </p>
              <p>
                We will let You know via email and/or a prominent notice on Our
                Service, prior to the change becoming effective and update the
                "Last updated" date at the top of this Privacy Policy. You are
                advised to review this Privacy Policy periodically for any
                changes. Changes to this Privacy Policy are effective when they
                are posted on this page.
              </p>
            </div>
          </Container>
        </div>
      </div>
    </Layout>
  );
};
