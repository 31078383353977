export const Selector = ({
  label,
  value,
  onChange,
  id,
  options,
  placeholder,
}) => {
  return (
    <div className="relative">
      {label && (
        <label
          className="absolute font-semibold text-main break-words cursor-default pointer-events-none text-xs pl-[12px] pt-[6px] z-10"
          htmlFor={id}
          id={`${id}-label`}
        >
          {label}
        </label>
      )}
      <div className="relative">
        <select
          className={`appearance-none box-border rounded-md text-[18px] w-full text-black block text-left px-[12px] pr-[36px] border-[1px] border-line bg-b-main min-h-[60px] hover:border-acent focus:outline-none ${
            label ? "pt-[18px]" : ""
          } ${!value ? "text-gray-400" : ""}`}
          id={id}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        >
          {placeholder && (
            <option value="" disabled>
              {placeholder}
            </option>
          )}
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
    </div>
  );
};
