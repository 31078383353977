import React, { useState } from "react";
import { Button, FullScreenModal } from "./common";
import { Close } from "./icons";
import { Link } from "react-router-dom";

export const SettingModal = ({ isOpen, onClose }) => {
  return (
    <FullScreenModal isOpen={isOpen}>
      <div className="bg-[#f9fafb] flex flex-col h-full">
        <div className="p-6 flex-shrink-0">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold">Setting</h2>
            <div
              onClick={onClose}
              className="bg-white border rounded-lg border-gray-200 p-1 cursor-pointer"
            >
              <Close className="size-[22px]" />
            </div>
          </div>
        </div>

        <div className="flex-grow overflow-y-auto px-6">
          <div className="bg-white border rounded-lg border-gray-200 px-2">
            <Link to={"/profile/edit"}>
              <div className="py-3 cursor-pointer">Edit Profile</div>
            </Link>
          </div>
          <div className="mt-8 bg-white border rounded-lg border-gray-200 px-2 flex flex-col divide-y">
            <Link to="mailto:my@talivia.com">
              <div className="py-3 cursor-pointer">Support</div>
            </Link>
            <div className="py-3 cursor-pointer">Terms and Conditions</div>
            <div className="py-3 cursor-pointer">Privacy Policy</div>
            <Link to={"/logout"}>
              <div className="py-3 font-semibold text-red-500 cursor-pointer">
                Logout
              </div>
            </Link>
          </div>
        </div>
      </div>
    </FullScreenModal>
  );
};
