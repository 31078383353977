import { Button, FullScreenModal } from "components/common";
import { useNavigate } from "react-router-dom";

export const SubscriptionSuccess = () => {
  const navigate = useNavigate();

  const onContinue = () => {
    // if saved join group, redirectTo, localStorage prev link
    navigate("/");
  };

  return (
    <FullScreenModal isOpen showConfetti={true} zoomType="in">
      <div className="flex justify-center items-center h-full">
        <div>
          <div className="text-center text-[50px]">🎉</div>
          <div className="text-[30px] font-semibold">Success!</div>
        </div>
        <div className="absolute bottom-0 left-0 w-full p-2 py-4">
          <Button onClick={onContinue} className="w-full">
            Continue
          </Button>
        </div>
      </div>
    </FullScreenModal>
  );
};
