import { useParams } from "react-router";
import { Button } from "components/common";
import { Layout } from "components/common/Layout";
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { useState, useRef, useEffect } from "react";
import { format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeft, Logo } from "components/icons";
import { useGroupInfo } from "hooks/group/useGroupInfo";

const queryGetMessages = ({ pageParam = 1, queryKey }) => {
  const [, groupId] = queryKey;
  return fetch(`/api/private/group/${groupId}/messages?page=${pageParam}`).then(
    (res) => res.json()
  );
};

const querySendMessages = (text, groupId) => {
  return fetch(`/api/private/group/${groupId}/message/send`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ text }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success) {
        return res;
      } else {
        throw new Error(res.message);
      }
    });
};

const Message = ({ text, type, author, createdAt, isOwner }) => {
  const formattedText = text?.replace(/\n/g, "\n") || "";

  if (type === "") return null;

  const renderTime = (isOwner) => {
    if (!createdAt) return null;
    const date = new Date(createdAt);
    return (
      <div
        className={`text-[10px] opacity-70 mb-[1px] ${
          isOwner ? "text-right" : "text-left"
        }`}
      >
        {format(date, "HH:mm dd.MM")}
      </div>
    );
  };

  const renderAvatar = () =>
    author &&
    author.image && (
      <Link to={`/profile/${author?.userId}`}>
        <div
          className="w-8 h-8 rounded-full bg-cover bg-center"
          style={{ backgroundImage: `url(${author.image})` }}
        />
      </Link>
    );

  if (type === "system_message") {
    return (
      <div className="flex justify-center mb-2">
        <div className="p-3 rounded-lg bg-gray-300 text-black text-center">
          <p className="text-sm whitespace-pre-wrap">{formattedText}</p>
        </div>
      </div>
    );
  }

  if (type === "init_message") {
    return (
      <div className="flex justify-start mb-2 max-w-[80%]">
        <Logo className="min-w-8 size-8 mr-2" />
        <div className="p-3 rounded-lg bg-gray-100 text-black">
          <p className="text-sm whitespace-pre-wrap">
            Group successfully created! Solo travelers are already looking to
            join the trip with you 🌍
          </p>
        </div>
      </div>
    );
  }

  if (type === "user_message") {
    return (
      <div className={`flex ${isOwner ? "justify-end" : "justify-start"} mb-2`}>
        <div
          className={`flex ${
            isOwner ? "flex-row-reverse" : "flex-row"
          } items-end max-w-[80%]`}
        >
          <div className={`${isOwner ? "ml-2" : "mr-2"}`}>{renderAvatar()}</div>
          <div className="flex flex-col">
            {renderTime(isOwner)}
            <div
              className={`p-3 rounded-lg ${
                isOwner
                  ? "bg-blue-500 text-white rounded-br-none"
                  : "bg-gray-100 text-black rounded-bl-none"
              }`}
            >
              {!isOwner && author && (
                <div className="font-bold text-sm mb-1">{author.name}</div>
              )}
              <p className="text-sm whitespace-pre-wrap">{formattedText}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export const Chat = () => {
  const { groupId } = useParams();
  const [text, setText] = useState("");
  const scrollableRef = useRef(null);
  const firstRender = useRef(true);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data: groupInfo } = useGroupInfo({ groupId });

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, refetch } =
    useInfiniteQuery({
      queryKey: ["GET_CHAT_LIST", groupId],
      queryFn: queryGetMessages,
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.data.length < 20) return undefined;
        return pages.length + 1;
      },
    });

  const { mutate: onSendMessage, isLoading } = useMutation({
    mutationFn: (text) => querySendMessages(text, groupId),
    onSuccess: () => {
      setText("");
      queryClient.setQueryData(["GET_CHAT_LIST", groupId], (oldData) => {
        if (oldData) {
          return {
            ...oldData,
            pages: [oldData.pages[0]],
            pageParams: [oldData.pageParams[0]],
          };
        }
        return oldData;
      });
      queryClient
        .invalidateQueries(["GET_CHAT_LIST", groupId])
        .then(() => scrollToBottom());
    },
  });

  const messages = data?.pages?.flatMap((page) => page.data) || [];

  const handleSendMessage = () => {
    onSendMessage(text);
  };

  const scrollToBottom = () => {
    if (scrollableRef.current) {
      setTimeout(() => {
        scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
      }, 0);
    }
  };

  useEffect(() => {
    if (firstRender.current && messages.length) {
      scrollToBottom();
      firstRender.current = false;
    }
  }, [messages]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        scrollableRef.current.scrollTop < 200 &&
        hasNextPage &&
        !isFetchingNextPage
      ) {
        fetchNextPage();
      }
    };

    const scrollableElement = scrollableRef.current;
    scrollableElement.addEventListener("scroll", handleScroll);

    return () => {
      scrollableElement.removeEventListener("scroll", handleScroll);
    };
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  return (
    <Layout showMenu={false} pageTitle="Chat">
      <div className="flex flex-col h-screen w-full">
        <div className="sticky top-0 z-10 bg-white px-4 py-2 border-b flex items-center justify-between">
          <div onClick={() => navigate(-1)} className="w-[30px]">
            <ArrowLeft className="size-[20px] min-w-[20px] cursor-pointer pr-[4px]" />
          </div>
          <Link
            to={`/group/${groupId}`}
            className="flex items-center text-left flex-grow gap-2 pl-4"
          >
            <img
              src={`${groupInfo?.data?.images[0]}`}
              className="size-[30px] rounded-full"
            />
            <h1 className="text-lg font-semibold">{groupInfo?.data?.title}</h1>
          </Link>
          <div className="w-[30px]"></div>
        </div>

        <div className="p-4 overflow-y-auto flex-grow" ref={scrollableRef}>
          <div className="flex flex-col-reverse">
            {messages.map((msg) => (
              <Message key={msg?.messageId} {...msg} />
            ))}
          </div>
        </div>

        <div className="sticky bottom-0 z-10 bg-white p-4 border-t">
          <div className="flex gap-3">
            <textarea
              rows={1}
              value={text}
              onChange={(e) => setText(e.target.value)}
              className="w-full appearance-none h-auto resize-none box-border text-[14px] md:text-[20px] rounded-md text-black block text-left px-[6px] py-[6px] border-[1px] border-line bg-b-main focus:border-acent focus:outline-none hover:border-acent"
            />
            <Button
              onClick={handleSendMessage}
              disabled={!text.trim() || isLoading}
            >
              Send
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};
