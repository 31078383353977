import {
  format,
  parseISO,
  isToday,
  isYesterday,
  isThisWeek,
  differenceInCalendarDays,
} from "date-fns";

export function calculateAge(birthdate) {
  const birthdateObj = new Date(birthdate);
  const currentDate = new Date();
  let age = currentDate.getFullYear() - birthdateObj.getFullYear();
  const monthDiff = currentDate.getMonth() - birthdateObj.getMonth();
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && currentDate.getDate() < birthdateObj.getDate())
  ) {
    age--;
  }

  return age;
}

export const formatDate = (dateString) => {
  if (!dateString) return "N/A";

  try {
    return format(parseISO(dateString), "MMM d");
  } catch (error) {
    return "Invalid Date";
  }
};

export function formatChatTime(dateString) {
  const date = new Date(dateString);
  const now = new Date();

  if (isToday(date)) {
    return format(date, "HH:mm");
  } else if (isYesterday(date)) {
    return "Yesterday";
  } else if (isThisWeek(date)) {
    return format(date, "EEEE"); // Full day name
  } else if (differenceInCalendarDays(now, date) < 365) {
    return format(date, "d MMM"); // e.g., "30 Jul"
  } else {
    return format(date, "dd.MM.yy");
  }
}

export function buildQueryUrl(baseUrl, params) {
  const url = new URL(window.location.origin + baseUrl);
  const searchParams = new URLSearchParams(params);
  url.search = searchParams.toString();
  return url.toString();
}
