import React, { useEffect, useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useUser } from "hooks/useUser";
import { useNavigate } from "react-router-dom";

export const Logout = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { refetch } = useUser();

  const logout = useCallback(() => {
    fetch("/api/auth/logout")
      .then(() => refetch())
      .then(() => queryClient.clear())
      .then(() => navigate("/"));
  }, [navigate]);

  useEffect(() => {
    logout();
  }, [logout]);

  return null;
};
