export const Accounts = ({ className }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[className, "fill-current"].filter(Boolean).join(" ")}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 6C11 6 14.1066 6 16.3033 8.1967C16.3033 8.1967 18.5 10.3934 18.5 13.5C18.5 13.5 18.5 16.6066 16.3033 18.8033C16.3033 18.8033 14.1066 21 11 21C11 21 7.8934 21 5.6967 18.8033C5.6967 18.8033 3.5 16.6066 3.5 13.5C3.5 13.5 3.5 10.3934 5.6967 8.1967C5.6967 8.1967 7.8934 6 11 6ZM11 8C11 8 8.72182 8 7.11091 9.61091C7.11091 9.61091 5.5 11.2218 5.5 13.5C5.5 13.5 5.5 15.7782 7.11091 17.3891C7.11091 17.3891 8.72182 19 11 19C11 19 13.2782 19 14.8891 17.3891C14.8891 17.3891 16.5 15.7782 16.5 13.5C16.5 13.5 16.5 11.2218 14.8891 9.61091C14.8891 9.61091 13.2782 8 11 8Z"
        fill="current"
      />
      <path
        d="M25.0766 17.3891C23.4657 19 21.1875 19 21.1875 19C20.6352 19 20.1875 19.4477 20.1875 20C20.1875 20.5523 20.6352 21 21.1875 21C24.2941 21 26.4908 18.8033 26.4908 18.8033C28.6875 16.6066 28.6875 13.5 28.6875 13.5C28.6875 10.3934 26.4908 8.1967 26.4908 8.1967C24.2941 6 21.1875 6 21.1875 6C20.1562 6.00314 19.1638 6.27221 19.1638 6.27221C18.7281 6.39038 18.425 6.78603 18.425 7.2375L18.425 7.24778C18.4259 7.33274 18.4376 7.41725 18.4599 7.49926C18.578 7.93499 18.9735 8.2375 19.425 8.2375L19.4353 8.23745C19.5202 8.23657 19.6047 8.22488 19.6867 8.20263C20.4252 8.0024 21.1875 8 21.1875 8C23.4657 8 25.0766 9.61091 25.0766 9.61091C26.6875 11.2218 26.6875 13.5 26.6875 13.5C26.6875 15.7782 25.0766 17.3891 25.0766 17.3891Z"
        fill="current"
      />
      <path
        d="M15.61 22.1255C17.7775 23.2515 19.1818 25.2499 19.1818 25.2499C19.3691 25.5164 19.6743 25.675 20 25.675C20.0112 25.675 20.0224 25.6748 20.0335 25.6744C20.2278 25.6679 20.4159 25.6049 20.575 25.4931C20.792 25.3406 20.9395 25.1082 20.9851 24.8469C20.995 24.7901 21 24.7326 21 24.675L20.9998 24.6534C20.9955 24.455 20.9323 24.2623 20.8182 24.1C19.133 21.7019 16.532 20.3507 16.532 20.3507C13.931 18.9995 11 19 11 19C8.06899 19 5.46801 20.3507 5.46801 20.3507C2.86717 21.7018 1.182 24.0997 1.182 24.0997C1.06367 24.2681 1 24.4692 1 24.675C1 24.6981 1.0008 24.7212 1.00241 24.7443C1.02329 25.0448 1.17855 25.3199 1.42504 25.4931C1.59342 25.6115 1.7942 25.675 2 25.675L2.00299 25.675C2.05964 25.6748 2.11618 25.6698 2.17198 25.6601C2.43325 25.6144 2.66569 25.4669 2.81818 25.2499C4.22252 23.2515 6.39001 22.1255 6.39001 22.1255C8.55749 20.9995 11 21 11 21C13.4425 21 15.61 22.1255 15.61 22.1255Z"
        fill="current"
      />
      <path
        d="M29.3685 25.2488C29.5557 25.5155 29.8616 25.675 30.1875 25.675C30.1985 25.675 30.2095 25.6748 30.2205 25.6745C30.4147 25.668 30.6029 25.6052 30.762 25.4935C31.0287 25.3063 31.1875 25.0009 31.1875 24.675C31.1875 24.664 31.1873 24.653 31.187 24.642C31.1805 24.4478 31.1177 24.2596 31.006 24.1005C31.0053 24.0995 31.0047 24.0986 31.0047 24.0986C29.3207 21.7004 26.7201 20.3496 26.7201 20.3496C24.1188 18.9983 21.1875 19 21.1875 19C20.9223 19.0002 20.6674 19.1057 20.48 19.2933C20.2927 19.4808 20.1875 19.735 20.1875 20C20.1875 20.5523 20.6352 21 21.1875 21C23.63 20.9985 25.7982 22.1244 25.7982 22.1244C27.9653 23.2501 29.3685 25.2488 29.3685 25.2488Z"
        fill="current"
      />
    </svg>
  );
};
