import { useQuery } from "@tanstack/react-query";

const queryFn = async (groupId) =>
  fetch(`/api/public/group/${groupId}`).then((res) => res.json());

export const useGroupInfo = (params, options = {}) => {
  const { groupId } = params;

  return useQuery({
    queryKey: ["GET_GROUP", groupId],
    queryFn: () => queryFn(groupId),
    enabled: Boolean(groupId),
  });
};
