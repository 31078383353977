export const Account = ({ className }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[className, "fill-current"].filter(Boolean).join(" ")}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 3C16 3 17.8304 3 19.5035 3.70768C19.5035 3.70768 21.1188 4.39089 22.364 5.63604C22.364 5.63604 23.6091 6.88119 24.2923 8.49649C24.2923 8.49649 25 10.1696 25 12C25 12 25 13.8304 24.2923 15.5035C24.2923 15.5035 23.6091 17.1188 22.364 18.364C22.364 18.364 21.1188 19.6091 19.5035 20.2923C19.5035 20.2923 17.8304 21 16 21C16 21 14.1696 21 12.4965 20.2923C12.4965 20.2923 10.8812 19.6091 9.63604 18.364C9.63604 18.364 8.39089 17.1188 7.70768 15.5035C7.70768 15.5035 7 13.8304 7 12C7 12 7 10.1696 7.70768 8.49649C7.70768 8.49649 8.39089 6.88119 9.63604 5.63604C9.63604 5.63604 10.8812 4.39089 12.4965 3.70767C12.4965 3.70767 14.1696 3 16 3ZM16 5C16 5 13.1005 5 11.0503 7.05025C11.0503 7.05025 9 9.1005 9 12C9 12 9 14.8995 11.0503 16.9497C11.0503 16.9497 13.1005 19 16 19C16 19 18.8995 19 20.9497 16.9497C20.9497 16.9497 23 14.8995 23 12C23 12 23 9.1005 20.9497 7.05025C20.9497 7.05025 18.8995 5 16 5Z"
        fill="current"
      />
      <path
        d="M22.5005 22.7409C25.5174 24.4828 27.2589 27.4999 27.2589 27.4999C27.3915 27.7296 27.6099 27.8973 27.8661 27.9659C27.9505 27.9886 28.0376 28 28.125 28C28.1355 28 28.146 27.9999 28.1564 27.9995C28.3212 27.9943 28.4821 27.9485 28.6249 27.8661C28.9344 27.6875 29.125 27.3573 29.125 27C29.125 26.9846 29.1246 26.9691 29.1239 26.9537C29.1165 26.7941 29.071 26.6385 28.9911 26.5001C26.9816 23.0188 23.5006 21.0089 23.5006 21.0089C20.0196 18.9989 16 18.9989 16 18.9989C11.9804 18.9989 8.4994 21.0089 8.4994 21.0089C5.01869 23.0187 3.00924 26.4996 3.00924 26.4996C2.9215 26.6516 2.875 26.8245 2.875 27L2.87516 27.0181C2.87664 27.0995 2.88803 27.1803 2.9091 27.2589C2.97776 27.5151 3.14538 27.7335 3.37508 27.8661C3.52708 27.9538 3.69949 28 3.875 28C3.89351 28 3.91201 27.9995 3.93049 27.9985C4.26751 27.9797 4.57233 27.7923 4.74107 27.4999C6.48262 24.4828 9.49948 22.7409 9.49948 22.7409C12.5163 20.9989 16 20.9989 16 20.9989C19.4837 20.9989 22.5005 22.7409 22.5005 22.7409Z"
        fill="current"
      />
    </svg>
  );
};
