export const Chat = ({ className }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[className, "fill-current"].filter(Boolean).join(" ")}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 19H9.30354L4.62852 22.7778C4.19896 23.1249 3.56933 23.0581 3.22221 22.6285C3.07843 22.4506 3 22.2288 3 22V6C3 6 3 5.17157 3.58579 4.58579C3.58579 4.58579 4.17157 4 5 4H21C21 4 21.8284 4 22.4142 4.58579C22.4142 4.58579 23 5.17157 23 6V17C23 17 23 17.8284 22.4142 18.4142C22.4142 18.4142 21.8284 19 21 19ZM21 17V6H5V19.9062L8.32148 17.2222C8.4994 17.0784 8.72124 17 8.95 17H21Z"
        fill="current"
      />
      <path
        d="M27 10H22C21.4477 10 21 10.4477 21 11C21 11.5523 21.4477 12 22 12H27V25.9062L23.6785 23.2222C23.5006 23.0784 23.2788 23 23.05 23H11V18C11 17.4477 10.5523 17 10 17C9.44772 17 9 17.4477 9 18V23C9 23.8284 9.58579 24.4142 9.58579 24.4142C10.1716 25 11 25 11 25H22.6965L27.3715 28.7778C27.5494 28.9216 27.7712 29 28 29C28.5523 29 29 28.5523 29 28V12C29 11.1716 28.4142 10.5858 28.4142 10.5858C27.8284 10 27 10 27 10Z"
        fill="current"
      />
    </svg>
  );
};
