export const Calendar = ({ className }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[className, "fill-current"].filter(Boolean).join(" ")}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 4V3C21 2.44772 21.4477 2 22 2C22.5523 2 23 2.44772 23 3V4H26C26 4 26.8284 4 27.4142 4.58579C27.4142 4.58579 28 5.17157 28 6V26C28 26 28 26.8284 27.4142 27.4142C27.4142 27.4142 26.8284 28 26 28H6C6 28 5.17157 28 4.58579 27.4142C4.58579 27.4142 4 26.8284 4 26V6C4 6 4 5.17157 4.58579 4.58579C4.58579 4.58579 5.17157 4 6 4H9V3C9 2.44772 9.44772 2 10 2C10.5523 2 11 2.44772 11 3V4H21ZM6 12V26H26V12H6ZM26 10H6V6H9V7C9 7.55228 9.44772 8 10 8C10.5523 8 11 7.55228 11 7V6H21V7C21 7.55228 21.4477 8 22 8C22.5523 8 23 7.55228 23 7V6H26V10Z"
        fill="current"
      />
    </svg>
  );
};
