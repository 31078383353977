import { Button } from "components/common";
import { Layout } from "components/common/Layout";
import { useUser } from "hooks/useUser";
import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { formatChatTime } from "utils";
import { Logo, Message } from "components/icons";

const queryFn = async () =>
  fetch(`/api/private/chat/list`).then((res) => res.json());

const MessageSkeleton = () => (
  <div className="w-full flex items-center p-3 animate-pulse">
    <div className="h-12 w-12 bg-gray-300 rounded-full mr-3 flex-shrink-0"></div>
    <div className="flex-grow min-w-0">
      <div className="flex justify-between items-center">
        <div className="bg-gray-300 h-4 w-1/2 rounded"></div>
        <div className="bg-gray-300 h-4 w-1/4 rounded"></div>
      </div>
      <div className="flex flex-col">
        <div className="flex items-center mt-1">
          <div className="bg-gray-300 h-4 w-1/4 rounded mr-1"></div>
          <div className="bg-gray-300 h-4 w-1/3 rounded"></div>
        </div>
        <div className="bg-gray-300 h-4 w-3/4 rounded mt-2"></div>
      </div>
    </div>
  </div>
);

export const Messages = () => {
  // TODO: add useInfiniteQuery
  const { isAuth } = useUser();
  const navigate = useNavigate();

  const { data, isLoading } = useQuery({
    queryKey: ["GET_CHAT_LIST"],
    queryFn,
    enabled: Boolean(isAuth),
  });

  const onCreateGroupClick = useCallback(() => {
    if (!isAuth) return navigate("/login");
    return navigate("/group/create");
  }, [isAuth]);

  const actionButton = (
    <Button className="w-full" onClick={onCreateGroupClick}>
      Create group
    </Button>
  );

  if (isLoading && isAuth) {
    return (
      <Layout>
        <div className="w-full">
          <MessageSkeleton />
          <MessageSkeleton />
          <MessageSkeleton />
          <MessageSkeleton />
          <MessageSkeleton />
          <MessageSkeleton />
          <MessageSkeleton />
        </div>
      </Layout>
    );
  }

  if ((data?.data || [])?.length === 0) {
    return (
      <Layout actionButtonSlot={actionButton}>
        <div className="w-full">
          <div className="flex flex-col items-center justify-center w-full h-full">
            <Message className="w-16 h-16 text-gray-300 mb-2" />
            <h3 className="text-xl font-semibold text-gray-700 mb-1">
              No messages
            </h3>
            <p className="text-sm text-gray-500">
              Join any group to see messages here
            </p>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout actionButtonSlot={actionButton}>
      <div className="w-full">
        {(data?.data || []).map((chat, _idx) => (
          <Link
            to={`/chat/${chat.groupId}`}
            key={chat.groupId}
            className="w-full flex items-center p-3 hover:bg-gray-100 cursor-pointer"
          >
            <img
              src={chat.image}
              className="h-12 w-12 rounded-full object-cover mr-3 flex-shrink-0"
              alt={chat.title}
            />
            <div className="flex-grow min-w-0">
              {/* min-w-0 allows flex child to shrink below its minimum content size */}
              <div className="flex justify-between items-center">
                <h3 className="font-semibold text-gray-900 line-clamp-1">
                  {chat.title}
                </h3>
                <span className="text-sm text-gray-500 ml-2 flex-shrink-0">
                  {formatChatTime(chat?.lastMessage?.createdAt)}
                </span>
              </div>
              <div className="flex flex-col">
                {chat.lastMessage.type === "init_message" ||
                !chat.lastMessage.author ? (
                  <div className="flex items-center">
                    <Logo className="w-5 h-5 mr-1 flex-shrink-0" />
                    <div className="text-sm text-gray-900">Notification</div>
                  </div>
                ) : (
                  <div className="flex">
                    <div
                      className="w-5 h-5 rounded-full bg-cover bg-center mr-1 flex-shrink-0"
                      style={{
                        backgroundImage: `url(${chat.lastMessage.author.image})`,
                      }}
                    />
                    <div className="text-sm text-gray-900">
                      {chat.lastMessage.author.name}
                    </div>
                  </div>
                )}
                <p className="text-sm text-gray-600 line-clamp-1 mt-auto">
                  {chat.lastMessage.text === "Success"
                    ? "Group successfully created!"
                    : chat.lastMessage.text}
                </p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </Layout>
  );
};
