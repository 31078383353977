import { Container } from "components/common";
import { Layout } from "components/common/Layout";
import { useInfiniteQuery } from "@tanstack/react-query";
import InfiniteScroll from "react-infinite-scroll-component";

import { Link, useNavigate } from "react-router-dom";
import { Accounts, Calendar } from "components/icons";
import { formatDate } from "utils";
import { countriesMap } from "constants";
import { SearchInput } from "../components/common/SearchInput";

const queryFn = ({ pageParam = 1 }) =>
  fetch(`/api/public/groups?page=${pageParam}`).then((res) => res.json());

export const GroupCard = ({
  groupId,
  title,
  membersCount,
  images,
  startDate,
  endDate,
  country,
}) => {
  return (
    <Link
      to={`/group/${groupId}`}
      className="rounded-lg border-solid border-[1px] overflow-hidden cursor-pointer"
    >
      <div className="p-1">
        <div className="relative rounded-md overflow-hidden">
          <img
            src={images?.[0]}
            className="h-[160px] bg-gray-800 object-cover w-full"
          />
          <div className="absolute bottom-1 left-1 right-1 flex justify-between opacity-95 gap-2">
            {countriesMap[country] ? (
              <div className="flex items-center gap-1 bg-white rounded-md px-1">
                <div className="text-[13px] text-third line-clamp-1">
                  {`${countriesMap[country].emoji} ${countriesMap[country].name}`}
                </div>
              </div>
            ) : null}
            <div className="flex items-center gap-1 bg-white rounded-md px-1">
              <Accounts className="size-[14px] min-w-[14px] text-third" />
              <div className="text-[13px] text-third">{membersCount}</div>
            </div>
          </div>
        </div>
        <div className="p-2 pb-1">
          <div className="flex items-center gap-1">
            <Calendar className="size-[12px] min-w-[12px] text-third" />
            <div className="text-[13px] text-third">{`${formatDate(
              startDate
            )} - ${formatDate(endDate)}`}</div>
          </div>
          <div className="font-semibold line-clamp-2">{title}</div>
        </div>
      </div>
    </Link>
  );
};

export const SkeletonGroupCard = () => {
  return (
    <div className="rounded-lg border-solid border-[1px] overflow-hidden animate-pulse">
      <div className="p-1">
        <div className="relative rounded-md overflow-hidden">
          <div className="h-[160px] bg-gray-300 w-full" />
          <div className="absolute bottom-1 left-1 right-1 flex justify-between opacity-95 gap-2">
            <div className="w-20 h-5 bg-white rounded-md" />
            <div className="w-10 h-5 bg-white rounded-md" />
          </div>
        </div>
        <div className="p-2 pb-1">
          <div className="flex items-center gap-1">
            <div className="w-24 h-4 bg-gray-300 rounded" />
          </div>
          <div className="w-full h-5 mt-1 bg-gray-300 rounded" />
        </div>
      </div>
    </div>
  );
};

export const Main = () => {
  const navigate = useNavigate();
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useInfiniteQuery({
      queryKey: ["GET_GROUP"],
      queryFn,
      getNextPageParam: (lastPage, pages) => {
        const totalFetchedItems = pages.flatMap(
          (page) => page.data.list
        ).length;
        if (totalFetchedItems < lastPage.data.total) {
          return pages.length + 1;
        }
        return undefined;
      },
    });

  const groups = data?.pages.flatMap((page) => page.data.list) || [];

  return (
    <Layout>
      <Container className="pt-4">
        <div className="w-full mb-2 flex flex-col">
          <SearchInput
            label=""
            placeholder="Search Trips"
            type="text"
            onClick={() => {
              navigate("/search");
            }}
          />
        </div>
        <InfiniteScroll
          dataLength={groups.length}
          next={fetchNextPage}
          hasMore={hasNextPage}
          loader={<></>}
          endMessage={null}
        >
          <div className="grid grid-cols-2 gap-2">
            {isLoading
              ? Array.from({ length: 6 }).map((_, index) => (
                  <SkeletonGroupCard key={index} />
                ))
              : groups.map((el) => <GroupCard key={el.groupId} {...el} />)}
          </div>
        </InfiniteScroll>
        {isFetchingNextPage && (
          <div className="grid grid-cols-2 gap-2 mt-2">
            {Array.from({ length: 2 }).map((_, index) => (
              <SkeletonGroupCard key={index} />
            ))}
          </div>
        )}
      </Container>
    </Layout>
  );
};
