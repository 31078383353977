export const Instagram = ({ className }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[className, "fill-current"].filter(Boolean).join(" ")}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 10C16 10 18.4853 10 20.2426 11.7574C20.2426 11.7574 22 13.5147 22 16C22 16 22 18.4853 20.2426 20.2426C20.2426 20.2426 18.4853 22 16 22C16 22 13.5147 22 11.7574 20.2426C11.7574 20.2426 10 18.4853 10 16C10 16 10 13.5147 11.7574 11.7574C11.7574 11.7574 13.5147 10 16 10ZM16 12C16 12 14.3431 12 13.1716 13.1716C13.1716 13.1716 12 14.3431 12 16C12 16 12 17.6569 13.1716 18.8284C13.1716 18.8284 14.3431 20 16 20C16 20 17.6569 20 18.8284 18.8284C18.8284 18.8284 20 17.6569 20 16C20 16 20 14.3431 18.8284 13.1716C18.8284 13.1716 17.6569 12 16 12Z"
        fill="current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 10.5C3.5 10.5 3.5 7.60051 5.55025 5.55025C5.55025 5.55025 7.60051 3.5 10.5 3.5H21.5C21.5 3.5 24.3995 3.5 26.4497 5.55025C26.4497 5.55025 28.5 7.6005 28.5 10.5V21.5C28.5 21.5 28.5 24.3995 26.4497 26.4497C26.4497 26.4497 24.3995 28.5 21.5 28.5H10.5C10.5 28.5 7.6005 28.5 5.55025 26.4497C5.55025 26.4497 3.5 24.3995 3.5 21.5V10.5ZM5.5 10.5L5.5 21.5C5.5 21.5 5.5 23.5711 6.96447 25.0355C6.96447 25.0355 8.42893 26.5 10.5 26.5H21.5C21.5 26.5 23.5711 26.5 25.0355 25.0355C25.0355 25.0355 26.5 23.5711 26.5 21.5V10.5C26.5 10.5 26.5 8.42893 25.0355 6.96447C25.0355 6.96447 23.5711 5.5 21.5 5.5L10.5 5.5C10.5 5.5 8.42893 5.5 6.96447 6.96447C6.96447 6.96447 5.5 8.42893 5.5 10.5Z"
        fill="current"
      />
      <path
        d="M24 9.5C24 10.3284 23.3284 11 22.5 11C21.6716 11 21 10.3284 21 9.5C21 8.67163 21.6716 8 22.5 8C23.3284 8 24 8.67163 24 9.5Z"
        fill="current"
      />
    </svg>
  );
};
