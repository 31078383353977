export const Minus = ({ className }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[className, "fill-current"].filter(Boolean).join(" ")}
    >
      <path
        d="M5 17H27C27.5523 17 28 16.5523 28 16C28 15.4477 27.5523 15 27 15H5C4.44772 15 4 15.4477 4 16C4 16.5523 4.44772 17 5 17Z"
        fill="current"
      />
    </svg>
  );
};
