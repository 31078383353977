import { format } from "date-fns";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { Container, Layout } from "components/common";
import { GroupCard, SkeletonGroupCard } from "./Main";
import { buildQueryUrl } from "../utils";
import { BASE_SEARCH_URL, countriesMap } from "../constants";
import { Settings } from "../components/icons";

const queryFn =
  (params) =>
  ({ pageParam = 1 }) =>
    fetch(buildQueryUrl(BASE_SEARCH_URL, { ...params, page: pageParam })).then(
      (res) => res.json()
    );

export const SearchList = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const country = searchParams.get("country") || "";
  const startDate = searchParams.get("startDate") || "";
  const endDate = searchParams.get("endDate") || "";

  const countryInfo = countriesMap[country || "WW"];

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useInfiniteQuery({
      queryKey: ["SEARCH_GROUPS", { country, startDate, endDate }],
      queryFn: queryFn({ country, startDate, endDate }),
      getNextPageParam: (lastPage, pages) => {
        const totalFetchedItems = pages.flatMap(
          (page) => page.data.list
        ).length;
        if (totalFetchedItems < lastPage.data.total) {
          return pages.length + 1;
        }
        return undefined;
      },
    });

  const onClickBack = () => {
    const query = new URLSearchParams({
      country,
      startDate,
      endDate,
    });
    navigate("/search?" + query);
  };

  const groups = data?.pages.flatMap((page) => page.data.list) || [];

  return (
    <Layout title="Search Results" showBack onClickBack={onClickBack}>
      <Container>
        <div className="w-full pt-4 flex justify-center py-2">
          <div className="flex w-full pl-2 h-20 bg-gray-300 rounded-lg border-solid border-[1px]">
            <div className="font-bold text-5xl content-center">
              {countryInfo?.emoji}
            </div>
            <div className="w-full flex flex-row mt-4 ml-2 mr-2 justify-between">
              <div className="flex flex-col">
                <title className="font-semibold content-center line-clamp-2">
                  {countryInfo?.name}
                </title>
                <text className="text-[12px] text-third content-center">
                  {startDate && format(new Date(+startDate), "d MMM")} -{" "}
                  {endDate && format(new Date(+endDate), "d MMM")}
                </text>
              </div>
              <div onClick={onClickBack}>
                <Settings className="cursor-pointer" />
              </div>
            </div>
          </div>
        </div>
        <InfiniteScroll
          dataLength={groups.length}
          next={fetchNextPage}
          hasMore={hasNextPage}
          loader={<></>}
          endMessage={null}
        >
          <div className="grid grid-cols-2 gap-2">
            {isLoading
              ? Array.from({ length: 6 }).map((_, index) => (
                  <SkeletonGroupCard key={index} />
                ))
              : groups.map((el) => <GroupCard key={el.groupId} {...el} />)}
          </div>
        </InfiniteScroll>
        {isFetchingNextPage && (
          <div className="grid grid-cols-2 gap-2 mt-2">
            {Array.from({ length: 2 }).map((_, index) => (
              <SkeletonGroupCard key={index} />
            ))}
          </div>
        )}
      </Container>
    </Layout>
  );
};
