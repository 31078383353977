import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { Button, Container, Input, Textarea } from "components/common";
import { Layout } from "components/common/Layout";
import { useUser } from "hooks/useUser";
import { calculateAge } from "utils";
import { ArrowLeft } from "components/icons";

const fetchEdit = (info) =>
  fetch("/api/private/user/edit", {
    method: "POST",
    body: JSON.stringify(info),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success) return res;
      throw new Error(res.message);
    });

export const ProfileEdit = () => {
  const { userInfo, isAuth, refetch } = useUser();
  const navigate = useNavigate();

  const { mutate: onSubmit, isPending } = useMutation({
    mutationFn: () => fetchEdit(info),
    onSuccess: (res) => {
      if (res.success) {
        refetch();
        navigate(`/profile/${userInfo.userId}`);
      }
    },
  });

  const [info, setInfo] = useState({
    introduction: userInfo?.introduction,
    instagram: userInfo?.social?.instagram,
    tiktok: userInfo?.social?.tiktok,
  });

  const onChangeInfo = useCallback(
    (field) => (value) => {
      setInfo((prevInfo) => ({ ...prevInfo, [field]: value }));
    },
    []
  );

  useEffect(() => {
    if (!isAuth) navigate("/login");
  }, [isAuth, navigate]);

  const actionButton = (
    <Button className="w-full" onClick={onSubmit} isLoading={isPending}>
      Save
    </Button>
  );

  return (
    <Layout className="relative" actionButtonSlot={actionButton}>
      <div
        onClick={() => navigate(-1)}
        className="absolute top-2 left-2 bg-white border rounded-lg border-gray-200 p-1 cursor-pointer z-30"
      >
        <ArrowLeft className="size-[22px]" />
      </div>
      <div className="w-full">
        <div className="relative w-full">
          <img
            src={`${userInfo?.images?.[0]}`}
            className="h-[400px] bg-gray-800 object-cover w-full"
          />
          <div className="absolute inset-x-0 bottom-0 h-[50%] bg-gradient-to-t from-black/80 to-transparent" />
          <div className="absolute bottom-16 left-0 right-0 mx-auto w-full flex justify-center">
            <div className="font-semibold text-[30px] text-white text-center px-2">
              {`${userInfo?.name}, ${calculateAge(userInfo?.birthdate)}`}
            </div>
          </div>
        </div>
        <Container className="relative -mt-10 bg-white rounded-t-[30px] px-4">
          <div className="w-full flex justify-center py-2">
            <div className="w-20 h-1 bg-gray-300 rounded-full"></div>
          </div>
          <div className="w-full pt-2 flex flex-col gap-4">
            <div className="font-semibold">About me</div>
            <Textarea
              rows={6}
              label="Introduction"
              placeholder="Let's make some memories together 🌍"
              type="text"
              onChange={onChangeInfo("introduction")}
              value={info.introduction}
            />
            <Input
              label="Instagram"
              placeholder="Taliviacom"
              type="text"
              onChange={onChangeInfo("instagram")}
              value={info.instagram}
            />
            <Input
              label="TikTok"
              placeholder="Taliviacom"
              type="text"
              onChange={onChangeInfo("tiktok")}
              value={info.tiktok}
            />
          </div>
        </Container>
      </div>
    </Layout>
  );
};
