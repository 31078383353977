import { ArrowLeft } from "components/icons";
import { Menu } from "./Menu";
import { ScrollRestoration } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export const Layout = ({
  children,
  actionButtonSlot = null,
  showMenu = true,
  showBack,
  title,
  onClickBack,
  pageTitle = "Talivia - Find friends to travel together!",
}) => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="min-h-[calc(100dvh)] flex flex-col relative">
        {(showBack || title) && (
          <div className="sticky top-0 flex items-center justify-between w-full px-2 z-10 py-2 bg-bg1 border-solid border-0 border-b-[1px]">
            <div className="w-[30px]">
              {showBack && (
                <div onClick={onClickBack ? onClickBack : () => navigate(-1)}>
                  <ArrowLeft className="size-[20px] min-w-[20px] cursor-pointer pr-[4px]" />
                </div>
              )}
            </div>
            <div>
              <div className="font-semibold text-[18px]">{title}</div>
            </div>
            <div className="w-[30px]"></div>
          </div>
        )}
        <div className="flex-grow flex">{children}</div>
        <div className="sticky bottom-0 z-10">
          {actionButtonSlot && (
            <div className="px-2 pb-4">{actionButtonSlot}</div>
          )}
          {showMenu && (
            <div className="shadow-[0_-8px_16px_-6px_rgba(0,0,0,0.08)]">
              <Menu />
            </div>
          )}
        </div>
      </div>
      <ScrollRestoration />
    </>
  );
};
