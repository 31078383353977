import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import Confetti from "react-confetti";

export const FullScreenModal = ({
  children,
  isOpen,
  showConfetti = false,
  zoomType = "in",
}) => {
  const variants = {
    hidden: {
      scale: zoomType === "in" ? 0 : 2,
      opacity: 0,
    },
    visible: {
      scale: 1,
      opacity: 1,
      transition: { duration: 0.3, ease: "easeInOut" },
    },
    exit: {
      scale: zoomType === "in" ? 0 : 2,
      opacity: 0,
      transition: { duration: 0.3, ease: "easeInOut" },
    },
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 w-full min-h-[calc(100dvh)]">
      <motion.div
        className="h-full"
        animate="visible"
        exit="exit"
        variants={variants}
      >
        <div className="relative h-full">{children}</div>
        {showConfetti && <Confetti recycle={false} numberOfPieces={200} />}
      </motion.div>
    </div>
  );
};
