export const Message = ({ className }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[className, "fill-current"].filter(Boolean).join(" ")}
    >
      <path
        d="M12 14H20C20.5523 14 21 13.5523 21 13C21 12.4477 20.5523 12 20 12H12C11.4477 12 11 12.4477 11 13C11 13.5523 11.4477 14 12 14Z"
        fill="current"
      />
      <path
        d="M12 18H20C20.5523 18 21 17.5523 21 17C21 16.4477 20.5523 16 20 16H12C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z"
        fill="current"
      />
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M17.7144 28.0988L19.5712 25L27 25C27 25 27.8284 25 28.4142 24.4142C28.4142 24.4142 29 23.8284 29 23V7C29 7 29 6.17157 28.4142 5.58579C28.4142 5.58579 27.8284 5 27 5H5C5 5 4.17157 5 3.58579 5.58579C3.58579 5.58579 3 6.17157 3 7V23C3 23 3 23.8284 3.58579 24.4142C3.58579 24.4142 4.17157 25 5 25H12.4289L14.2797 28.089C14.2797 28.089 14.5603 28.5454 15.018 28.8013C15.018 28.8013 15.4757 29.0572 16 29.0572C16 29.0572 16.5243 29.0572 16.982 28.8013C16.982 28.8013 17.4397 28.5454 17.7144 28.0988ZM17.8547 23.9735C17.8547 23.9735 18.1213 23.5238 18.5753 23.2634C18.5753 23.2634 19.0331 23.0008 19.5609 23L27 23V7H5V23H12.4375C12.4375 23 12.9669 23.0008 13.4247 23.2634C13.4247 23.2634 13.8826 23.526 14.1498 23.9812L15.995 27.0606L15.9929 27.0554L15.9939 27.0556C15.9939 27.0556 15.9967 27.0572 16 27.0572L16.0023 27.0569L16.0061 27.0556C16.0061 27.0556 16.0089 27.054 16.0106 27.0512L17.8528 23.9767L17.8547 23.9735Z"
        fill="current"
      />
    </svg>
  );
};
