export const isDev = process.env.REACT_APP_IS_DEV === "true";

export const BASE_SEARCH_URL = "/api/public/groups/search";

export const config = {
  payments: {
    M: {
      price: 5,
      month: 1,
      link: isDev
        ? "https://buy.stripe.com/test_14kdS0gUK5UQ7mM5kk"
        : "https://buy.stripe.com/aEUdSGeqQ8Qv2u47ss",
      plink: isDev
        ? "plink_1PkKXJCLarW0eMq0kyX6Igxq"
        : "plink_1PkKDtCLarW0eMq0GfCdjbD1",
    },
    Y: {
      price: 50,
      month: 12,
      link: isDev
        ? "https://buy.stripe.com/test_cN28xGeMC1EAbD2001"
        : "https://buy.stripe.com/aEU9Cq82s2s79Ww4gh",
      plink: isDev
        ? "plink_1PkP4bCLarW0eMq0yutmhAhu"
        : "plink_1PkKKWCLarW0eMq0ipIlfPTn",
    },
    F: {
      price: 99,
      month: 200,
      link: "https://buy.stripe.com/4gw8ym96wfeT7OocMO",
      plink: "plink_1PkKOZCLarW0eMq0Ng57QUSj",
    },
  },
};

export const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const countriesMap = {
  WW: { name: "Worldwide", emoji: "🌍" },
  EU: { name: "Europe", emoji: "🌍" },
  AA: { name: "Asia", emoji: "🌍" },
  SS: { name: "South America", emoji: "🌍" },
  AF: { name: "Afghanistan", emoji: "🇦🇫" },
  AL: { name: "Albania", emoji: "🇦🇱" },
  DZ: { name: "Algeria", emoji: "🇩🇿" },
  AS: { name: "American Samoa", emoji: "🇦🇸" },
  AD: { name: "Andorra", emoji: "🇦🇩" },
  AO: { name: "Angola", emoji: "🇦🇴" },
  AI: { name: "Anguilla", emoji: "🇦🇮" },
  AQ: { name: "Antarctica", emoji: "🇦🇶" },
  AG: { name: "Antigua and Barbuda", emoji: "🇦🇬" },
  AR: { name: "Argentina", emoji: "🇦🇷" },
  AM: { name: "Armenia", emoji: "🇦🇲" },
  AW: { name: "Aruba", emoji: "🇦🇼" },
  AU: { name: "Australia", emoji: "🇦🇺" },
  AT: { name: "Austria", emoji: "🇦🇹" },
  AZ: { name: "Azerbaijan", emoji: "🇦🇿" },
  BS: { name: "Bahamas", emoji: "🇧🇸" },
  BH: { name: "Bahrain", emoji: "🇧🇭" },
  BD: { name: "Bangladesh", emoji: "🇧🇩" },
  BB: { name: "Barbados", emoji: "🇧🇧" },
  BY: { name: "Belarus", emoji: "🇧🇾" },
  BE: { name: "Belgium", emoji: "🇧🇪" },
  BZ: { name: "Belize", emoji: "🇧🇿" },
  BJ: { name: "Benin", emoji: "🇧🇯" },
  BM: { name: "Bermuda", emoji: "🇧🇲" },
  BT: { name: "Bhutan", emoji: "🇧🇹" },
  BO: { name: "Bolivia", emoji: "🇧🇴" },
  BA: { name: "Bosnia and Herzegovina", emoji: "🇧🇦" },
  BW: { name: "Botswana", emoji: "🇧🇼" },
  BR: { name: "Brazil", emoji: "🇧🇷" },
  IO: { name: "British Indian Ocean Territory", emoji: "🇮🇴" },
  VG: { name: "British Virgin Islands", emoji: "🇻🇬" },
  BN: { name: "Brunei", emoji: "🇧🇳" },
  BG: { name: "Bulgaria", emoji: "🇧🇬" },
  BF: { name: "Burkina Faso", emoji: "🇧🇫" },
  BI: { name: "Burundi", emoji: "🇧🇮" },
  KH: { name: "Cambodia", emoji: "🇰🇭" },
  CM: { name: "Cameroon", emoji: "🇨🇲" },
  CA: { name: "Canada", emoji: "🇨🇦" },
  CV: { name: "Cape Verde", emoji: "🇨🇻" },
  KY: { name: "Cayman Islands", emoji: "🇰🇾" },
  CF: { name: "Central African Republic", emoji: "🇨🇫" },
  TD: { name: "Chad", emoji: "🇹🇩" },
  CL: { name: "Chile", emoji: "🇨🇱" },
  CN: { name: "China", emoji: "🇨🇳" },
  CX: { name: "Christmas Island", emoji: "🇨🇽" },
  CC: { name: "Cocos (Keeling) Islands", emoji: "🇨🇨" },
  CO: { name: "Colombia", emoji: "🇨🇴" },
  KM: { name: "Comoros", emoji: "🇰🇲" },
  CG: { name: "Congo - Brazzaville", emoji: "🇨🇬" },
  CD: { name: "Congo - Kinshasa", emoji: "🇨🇩" },
  CK: { name: "Cook Islands", emoji: "🇨🇰" },
  CR: { name: "Costa Rica", emoji: "🇨🇷" },
  HR: { name: "Croatia", emoji: "🇭🇷" },
  CU: { name: "Cuba", emoji: "🇨🇺" },
  CY: { name: "Cyprus", emoji: "🇨🇾" },
  CZ: { name: "Czechia", emoji: "🇨🇿" },
  DK: { name: "Denmark", emoji: "🇩🇰" },
  DJ: { name: "Djibouti", emoji: "🇩🇯" },
  DM: { name: "Dominica", emoji: "🇩🇲" },
  DO: { name: "Dominican Republic", emoji: "🇩🇴" },
  EC: { name: "Ecuador", emoji: "🇪🇨" },
  EG: { name: "Egypt", emoji: "🇪🇬" },
  SV: { name: "El Salvador", emoji: "🇸🇻" },
  GQ: { name: "Equatorial Guinea", emoji: "🇬🇶" },
  ER: { name: "Eritrea", emoji: "🇪🇷" },
  EE: { name: "Estonia", emoji: "🇪🇪" },
  SZ: { name: "Eswatini", emoji: "🇸🇿" },
  ET: { name: "Ethiopia", emoji: "🇪🇹" },
  FJ: { name: "Fiji", emoji: "🇫🇯" },
  FI: { name: "Finland", emoji: "🇫🇮" },
  FR: { name: "France", emoji: "🇫🇷" },
  GF: { name: "French Guiana", emoji: "🇬🇫" },
  PF: { name: "French Polynesia", emoji: "🇵🇫" },
  GA: { name: "Gabon", emoji: "🇬🇦" },
  GM: { name: "Gambia", emoji: "🇬🇲" },
  GE: { name: "Georgia", emoji: "🇬🇪" },
  DE: { name: "Germany", emoji: "🇩🇪" },
  GH: { name: "Ghana", emoji: "🇬🇭" },
  GI: { name: "Gibraltar", emoji: "🇬🇮" },
  GR: { name: "Greece", emoji: "🇬🇷" },
  GL: { name: "Greenland", emoji: "🇬🇱" },
  GD: { name: "Grenada", emoji: "🇬🇩" },
  GU: { name: "Guam", emoji: "🇬🇺" },
  GT: { name: "Guatemala", emoji: "🇬🇹" },
  GN: { name: "Guinea", emoji: "🇬🇳" },
  GW: { name: "Guinea-Bissau", emoji: "🇬🇼" },
  GY: { name: "Guyana", emoji: "🇬🇾" },
  HT: { name: "Haiti", emoji: "🇭🇹" },
  HN: { name: "Honduras", emoji: "🇭🇳" },
  HK: { name: "Hong Kong SAR China", emoji: "🇭🇰" },
  HU: { name: "Hungary", emoji: "🇭🇺" },
  IS: { name: "Iceland", emoji: "🇮🇸" },
  IN: { name: "India", emoji: "🇮🇳" },
  ID: { name: "Indonesia", emoji: "🇮🇩" },
  IR: { name: "Iran", emoji: "🇮🇷" },
  IQ: { name: "Iraq", emoji: "🇮🇶" },
  IE: { name: "Ireland", emoji: "🇮🇪" },
  IL: { name: "Israel", emoji: "🇮🇱" },
  IT: { name: "Italy", emoji: "🇮🇹" },
  JM: { name: "Jamaica", emoji: "🇯🇲" },
  JP: { name: "Japan", emoji: "🇯🇵" },
  JO: { name: "Jordan", emoji: "🇯🇴" },
  KZ: { name: "Kazakhstan", emoji: "🇰🇿" },
  KE: { name: "Kenya", emoji: "🇰🇪" },
  KI: { name: "Kiribati", emoji: "🇰🇮" },
  KW: { name: "Kuwait", emoji: "🇰🇼" },
  KG: { name: "Kyrgyzstan", emoji: "🇰🇬" },
  LA: { name: "Laos", emoji: "🇱🇦" },
  LV: { name: "Latvia", emoji: "🇱🇻" },
  LB: { name: "Lebanon", emoji: "🇱🇧" },
  LS: { name: "Lesotho", emoji: "🇱🇸" },
  LR: { name: "Liberia", emoji: "🇱🇷" },
  LY: { name: "Libya", emoji: "🇱🇾" },
  LI: { name: "Liechtenstein", emoji: "🇱🇮" },
  LT: { name: "Lithuania", emoji: "🇱🇹" },
  LU: { name: "Luxembourg", emoji: "🇱🇺" },
  MO: { name: "Macau SAR China", emoji: "🇲🇴" },
  MK: { name: "North Macedonia", emoji: "🇲🇰" },
  MG: { name: "Madagascar", emoji: "🇲🇬" },
  MW: { name: "Malawi", emoji: "🇲🇼" },
  MY: { name: "Malaysia", emoji: "🇲🇾" },
  MV: { name: "Maldives", emoji: "🇲🇻" },
  ML: { name: "Mali", emoji: "🇲🇱" },
  MT: { name: "Malta", emoji: "🇲🇹" },
  MH: { name: "Marshall Islands", emoji: "🇲🇭" },
  MR: { name: "Mauritania", emoji: "🇲🇷" },
  MU: { name: "Mauritius", emoji: "🇲🇺" },
  MX: { name: "Mexico", emoji: "🇲🇽" },
  FM: { name: "Micronesia", emoji: "🇫🇲" },
  MD: { name: "Moldova", emoji: "🇲🇩" },
  MC: { name: "Monaco", emoji: "🇲🇨" },
  MN: { name: "Mongolia", emoji: "🇲🇳" },
  ME: { name: "Montenegro", emoji: "🇲🇪" },
  MS: { name: "Montserrat", emoji: "🇲🇸" },
  MA: { name: "Morocco", emoji: "🇲🇦" },
  MZ: { name: "Mozambique", emoji: "🇲🇿" },
  MM: { name: "Myanmar (Burma)", emoji: "🇲🇲" },
  NA: { name: "Namibia", emoji: "🇳🇦" },
  NR: { name: "Nauru", emoji: "🇳🇷" },
  NP: { name: "Nepal", emoji: "🇳🇵" },
  NL: { name: "Netherlands", emoji: "🇳🇱" },
  NC: { name: "New Caledonia", emoji: "🇳🇨" },
  NZ: { name: "New Zealand", emoji: "🇳🇿" },
  NI: { name: "Nicaragua", emoji: "🇳🇮" },
  NE: { name: "Niger", emoji: "🇳🇪" },
  NG: { name: "Nigeria", emoji: "🇳🇬" },
  NU: { name: "Niue", emoji: "🇳🇺" },
  NF: { name: "Norfolk Island", emoji: "🇳🇫" },
  KP: { name: "North Korea", emoji: "🇰🇵" },
  MP: { name: "Northern Mariana Islands", emoji: "🇲🇵" },
  NO: { name: "Norway", emoji: "🇳🇴" },
  OM: { name: "Oman", emoji: "🇴🇲" },
  PK: { name: "Pakistan", emoji: "🇵🇰" },
  PW: { name: "Palau", emoji: "🇵🇼" },
  PS: { name: "Palestinian Territories", emoji: "🇵🇸" },
  PA: { name: "Panama", emoji: "🇵🇦" },
  PG: { name: "Papua New Guinea", emoji: "🇵🇬" },
  PY: { name: "Paraguay", emoji: "🇵🇾" },
  PE: { name: "Peru", emoji: "🇵🇪" },
  PH: { name: "Philippines", emoji: "🇵🇭" },
  PL: { name: "Poland", emoji: "🇵🇱" },
  PT: { name: "Portugal", emoji: "🇵🇹" },
  PR: { name: "Puerto Rico", emoji: "🇵🇷" },
  QA: { name: "Qatar", emoji: "🇶🇦" },
  RO: { name: "Romania", emoji: "🇷🇴" },
  RU: { name: "Russia", emoji: "🇷🇺" },
  RW: { name: "Rwanda", emoji: "🇷🇼" },
  RE: { name: "Réunion", emoji: "🇷🇪" },
  WS: { name: "Samoa", emoji: "🇼🇸" },
  SM: { name: "San Marino", emoji: "🇸🇲" },
  SA: { name: "Saudi Arabia", emoji: "🇸🇦" },
  SN: { name: "Senegal", emoji: "🇸🇳" },
  RS: { name: "Serbia", emoji: "🇷🇸" },
  SC: { name: "Seychelles", emoji: "🇸🇨" },
  SL: { name: "Sierra Leone", emoji: "🇸🇱" },
  SG: { name: "Singapore", emoji: "🇸🇬" },
  SX: { name: "Sint Maarten", emoji: "🇸🇽" },
  SK: { name: "Slovakia", emoji: "🇸🇰" },
  SI: { name: "Slovenia", emoji: "🇸🇮" },
  SB: { name: "Solomon Islands", emoji: "🇸🇧" },
  SO: { name: "Somalia", emoji: "🇸🇴" },
  ZA: { name: "South Africa", emoji: "🇿🇦" },
  KR: { name: "South Korea", emoji: "🇰🇷" },
  SS: { name: "South Sudan", emoji: "🇸🇸" },
  ES: { name: "Spain", emoji: "🇪🇸" },
  LK: { name: "Sri Lanka", emoji: "🇱🇰" },
  SD: { name: "Sudan", emoji: "🇸🇩" },
  SR: { name: "Suriname", emoji: "🇸🇷" },
  SE: { name: "Sweden", emoji: "🇸🇪" },
  CH: { name: "Switzerland", emoji: "🇨🇭" },
  SY: { name: "Syria", emoji: "🇸🇾" },
  TW: { name: "Taiwan", emoji: "🇹🇼" },
  TJ: { name: "Tajikistan", emoji: "🇹🇯" },
  TZ: { name: "Tanzania", emoji: "🇹🇿" },
  TH: { name: "Thailand", emoji: "🇹🇭" },
  TL: { name: "Timor-Leste", emoji: "🇹🇱" },
  TG: { name: "Togo", emoji: "🇹🇬" },
  TK: { name: "Tokelau", emoji: "🇹🇰" },
  TO: { name: "Tonga", emoji: "🇹🇴" },
  TT: { name: "Trinidad and Tobago", emoji: "🇹🇹" },
  TN: { name: "Tunisia", emoji: "🇹🇳" },
  TR: { name: "Turkey", emoji: "🇹🇷" },
  TM: { name: "Turkmenistan", emoji: "🇹🇲" },
  TC: { name: "Turks and Caicos Islands", emoji: "🇹🇨" },
  TV: { name: "Tuvalu", emoji: "🇹🇻" },
  UG: { name: "Uganda", emoji: "🇺🇬" },
  UA: { name: "Ukraine", emoji: "🇺🇦" },
  AE: { name: "United Arab Emirates", emoji: "🇦🇪" },
  GB: { name: "United Kingdom", emoji: "🇬🇧" },
  US: { name: "United States", emoji: "🇺🇸" },
  UY: { name: "Uruguay", emoji: "🇺🇾" },
  UZ: { name: "Uzbekistan", emoji: "🇺🇿" },
  VU: { name: "Vanuatu", emoji: "🇻🇺" },
  VA: { name: "Vatican City", emoji: "🇻🇦" },
  VE: { name: "Venezuela", emoji: "🇻🇪" },
  VN: { name: "Vietnam", emoji: "🇻🇳" },
  WF: { name: "Wallis and Futuna", emoji: "🇼🇫" },
  EH: { name: "Western Sahara", emoji: "🇪🇭" },
  YE: { name: "Yemen", emoji: "🇾🇪" },
  ZM: { name: "Zambia", emoji: "🇿🇲" },
  ZW: { name: "Zimbabwe", emoji: "🇿🇼" },
};
