import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Button, Container } from "components/common";
import { Layout } from "components/common/Layout";
import { useUser } from "hooks/useUser";
import { calculateAge } from "utils";
import { PremiumModal } from "components/PremiumModal";
import { ArrowLeft, Check, Gear, Instagram, TikTok } from "components/icons";
import { SettingModal } from "components/SettingModal";

const fetchUserProfile = async (userId) =>
  fetch(`/api/public/user/${userId}`)
    .then((res) => res.json())
    .then((res) => {
      if (res.success) return res;
      throw new Error(res.message);
    });

const SkeletonProfile = () => (
  <Layout className="relative animate-pulse">
    <div className="w-full">
      <div className="relative w-full">
        <div className="h-[400px] bg-gray-300 w-full" />
        <div className="absolute bottom-16 left-0 right-0 mx-auto w-full flex justify-center">
          <div className="h-8 bg-gray-200 rounded w-1/2"></div>
        </div>
      </div>
      <Container className="relative -mt-10 bg-white rounded-t-[30px] px-4">
        <div className="w-full flex justify-center py-2">
          <div className="w-20 h-1 bg-gray-300 rounded-full"></div>
        </div>
        <div className="w-full pt-2">
          <div className="h-6 bg-gray-200 rounded w-1/4 mb-2"></div>
          <div className="h-4 bg-gray-200 rounded w-full mb-2"></div>
          <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
        </div>
        <div className="h-4 bg-gray-200 rounded w-2/3 mt-4"></div>
      </Container>
    </div>
  </Layout>
);

export const Profile = () => {
  const [isOpenPremiumModal, setIsOpenPremiumModal] = useState(false);
  const [isOpenSettingModal, setIsOpenSettingModal] = useState(false);
  const { userId } = useParams();
  const { userInfo, isAuth } = useUser();
  const navigate = useNavigate();

  const { data: userData, isLoading } = useQuery({
    queryKey: ["GET_USER", userId],
    queryFn: () => fetchUserProfile(userId),
    enabled: Boolean(userId),
  });

  useEffect(() => {
    if (!isAuth) navigate("/login");
  }, [isAuth, navigate]);

  const user = userData?.data || userInfo;
  const isOwner = userInfo?.userId === userId;

  const premiumFeatures = [
    "Unlimited Group Join",
    "Hide Ads",
    "Verified badge",
  ];

  if (isLoading) return <SkeletonProfile />;

  return (
    <>
      <Layout className="relative">
        <div
          onClick={() => navigate(-1)}
          className="absolute top-4 left-4 bg-white border rounded-lg border-gray-200 p-1 cursor-pointer z-30"
        >
          <ArrowLeft className="size-[26px]" />
        </div>
        {isOwner && (
          <div
            onClick={() => setIsOpenSettingModal(true)}
            className="absolute top-4 right-4 bg-white border rounded-lg border-gray-200 p-1 cursor-pointer z-30"
          >
            <Gear className="size-[26px]" />
          </div>
        )}
        <div className="w-full">
          <div className="relative w-full">
            <img
              src={`${user?.images?.[0]}`}
              className="h-[400px] bg-gray-800 object-cover w-full"
            />
            <div className="absolute inset-x-0 bottom-0 h-[50%] bg-gradient-to-t from-black/80 to-transparent" />
            <div className="absolute bottom-16 left-0 right-0 mx-auto w-full flex justify-center">
              <div className="font-semibold text-[30px] text-white text-center px-2">
                {`${user?.name}, ${calculateAge(user?.birthdate)}`}
              </div>
            </div>
          </div>
          <Container className="relative -mt-10 bg-white rounded-t-[30px] px-4">
            <div className="w-full flex justify-center py-2">
              <div className="w-20 h-1 bg-gray-300 rounded-full" />
            </div>
            <div className="w-full pt-2 flex flex-col gap-2">
              <div className="font-semibold">About me</div>
              <div className="text-[14px]">
                {user?.introduction || "Let's make some memories together 🌍"}
              </div>
              {isOwner && !user?.introduction ? (
                <Link to="/profile/edit">
                  <div class="px-2 py-1 text-sm font-semibold text-acent border border-acent rounded-full hover:bg-blue-50 w-fit">
                    Add Introduction
                  </div>
                </Link>
              ) : null}
            </div>
            <div className="mt-4 flex gap-2">
              <a
                href={`${
                  user?.social?.instagram
                    ? `https://www.instagram.com/${user.social.instagram}`
                    : "#"
                }`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className={`pl-2 pr-3 py-1 gap-1 flex text-sm border rounded-full w-fit ${
                    user?.social?.instagram
                      ? "text-acent border-acent cursor-pointer"
                      : "text-gray-400 border-gray-400 cursor-not-allowed"
                  }`}
                >
                  <Instagram className="size-5" />
                  <div>
                    {user?.social?.instagram
                      ? user?.social?.instagram
                      : "Not Set"}
                  </div>
                </div>
              </a>
              <a
                href={`${
                  user?.social?.tiktok
                    ? `https://tiktok.com/@n${user.social.tiktok}`
                    : "#"
                }`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className={`pl-2 pr-3 py-1 gap-1 flex text-sm border rounded-full w-fit ${
                    user?.social?.tiktok
                      ? "text-acent border-acent cursor-pointer"
                      : "text-gray-400 border-gray-400 cursor-not-allowed"
                  }`}
                >
                  <TikTok className="size-5" />
                  <div>
                    {user?.social?.tiktok ? user?.social?.tiktok : "Not Set"}
                  </div>
                </div>
              </a>
            </div>
            <div>{}</div>
            {isAuth && !userInfo.isActive && isOwner && (
              <div className="mt-20 bg-white border border-gray-200 rounded-lg p-4">
                <h3 className="text-lg font-semibold mb-2">
                  Upgrade to Premium
                </h3>
                <ul className="mb-4">
                  {premiumFeatures.map((feature) => (
                    <li
                      key={feature}
                      className="flex items-center space-x-2 mb-1"
                    >
                      <Check className="w-4 h-4 text-acent" />
                      <span className="text-sm">{feature}</span>
                    </li>
                  ))}
                </ul>
                <Button
                  className="w-full bg-acent text-white"
                  onClick={() => setIsOpenPremiumModal(true)}
                >
                  Go Premium
                </Button>
              </div>
            )}
          </Container>
        </div>
      </Layout>
      <PremiumModal
        isOpen={isOpenPremiumModal}
        onClose={() => setIsOpenPremiumModal(false)}
      />
      <SettingModal
        isOpen={isOpenSettingModal}
        onClose={() => setIsOpenSettingModal(false)}
      />
    </>
  );
};
