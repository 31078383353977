import { NavLink } from "react-router-dom";
import { Globe, Account, Chat } from "components/icons";
import { useUser } from "hooks/useUser";

export const Menu = () => {
  const { isAuth, userInfo } = useUser();

  const list = [
    {
      title: "Main",
      href: "/",
      icon: Globe,
    },
    {
      title: "Groups",
      href: "/messages",
      icon: Chat,
    },
    {
      title: "Profile",
      href: isAuth ? `/profile/${userInfo?.userId}` : `/login`,
      icon: Account,
    },
  ];

  return (
    <div className="w-full border-solid border-0 border-t-[1px]">
      <div className="grid grid-cols-3 bg-bg1">
        {list.map((link) => (
          <NavLink to={link.href} key={link.href}>
            {({ isActive }) => (
              <div className="flex justify-center p-4">
                <link.icon
                  className={["w-5 h-5", isActive ? "text-acent" : "text-third"]
                    .filter(Boolean)
                    .join(" ")}
                />
              </div>
            )}
          </NavLink>
        ))}
      </div>
    </div>
  );
};
