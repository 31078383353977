import { Button, Container, Layout } from "components/common";
import { Accounts, ArrowLeft, Calendar } from "components/icons";
import { useGroupInfo } from "hooks/group/useGroupInfo";
import { useUser } from "hooks/useUser";
import { useParams } from "react-router";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { formatDate } from "utils";
import { countriesMap } from "constants";
import { useMutation, useQuery } from "@tanstack/react-query";
import { PremiumModal } from "components/PremiumModal";
import { useState } from "react";

const queryOnJoinGroup = async (groupId) =>
  fetch(`/api/private/group/${groupId}/join`)
    .then((res) => res.json())
    .then((res) => {
      if (res.success) return res;
      throw new Error(res.message);
    });

const fetchUsers = async () =>
  fetch(`/api/public/group/users-list`)
    .then((res) => res.json())
    .then((res) => {
      if (res.success) return res;
      throw new Error(res.message);
    });

const UserAvatars = ({ users }) => {
  const randomNumber = Math.floor(Math.random() * (31 - 4 + 1)) + 4;

  if (!users || users.length === 0) return null;

  return (
    <div className="flex items-center mt-2">
      <div className="flex -space-x-3">
        {users.map((user, index) => (
          <div
            key={user.image}
            className="w-10 h-10 rounded-full bg-gray-300 bg-cover bg-center ring-2 ring-white"
            style={{ backgroundImage: `url(${user.image})` }}
          />
        ))}
      </div>
      <div className="w-10 h-10 rounded-full bg-gray-200 ring-2 ring-white flex items-center justify-center text-sm font-medium text-gray-700 -ml-3">
        +{randomNumber}
      </div>
    </div>
  );
};

const SkeletonGroup = () => (
  <Layout className="relative">
    <div className="w-full animate-pulse">
      <div className="relative w-full">
        <div className="h-[350px] bg-gray-300 w-full" />
      </div>
      <Container className="relative -mt-10 bg-white rounded-t-[30px] px-4">
        <div className="w-full flex justify-center py-2">
          <div className="w-20 h-1 bg-gray-300 rounded-full"></div>
        </div>
        <div className="w-full pt-2">
          <div className="h-8 bg-gray-300 rounded w-3/4 mb-2"></div>
          <div className="flex items-center justify-between mt-2">
            <div className="h-4 bg-gray-300 rounded w-1/3"></div>
            <div className="h-4 bg-gray-300 rounded w-1/4"></div>
          </div>
          <div className="h-4 bg-gray-300 rounded w-1/2 mt-2"></div>
          <div className="py-4">
            <div className="h-10 bg-gray-300 rounded w-full"></div>
          </div>
          <div className="pt-2">
            <div className="h-6 bg-gray-300 rounded w-1/3 mb-2"></div>
            <div className="h-4 bg-gray-300 rounded w-full mb-2"></div>
            <div className="h-4 bg-gray-300 rounded w-full mb-2"></div>
            <div className="h-4 bg-gray-300 rounded w-3/4"></div>
          </div>
        </div>
      </Container>
    </div>
  </Layout>
);

export const Group = () => {
  const { groupId } = useParams();
  const [isOpenPremiumModal, setIsOpenPremiumModal] = useState(false);
  const [searchParams] = useSearchParams();
  const groupType = searchParams.get("groupType");

  const { data, isLoading } = useGroupInfo({ groupId });
  const { isAuth, userInfo, refetch: refetchUserInfo } = useUser();
  const { userId } = userInfo || {};
  const isTiktokView = searchParams.get("view") === "tiktok";
  const navigate = useNavigate();

  const { mutate: onJoinGroup } = useMutation({
    mutationFn: () => queryOnJoinGroup(groupId),
    onSuccess: (res) => {
      if (res.success) {
        navigate(`/chat/${groupId}`);
        refetchUserInfo();
      }
    },
  });

  const { data: users } = useQuery({
    queryKey: ["GET_USERS", groupId],
    queryFn: fetchUsers,
    enabled: Boolean(isTiktokView),
  });

  const onClickBack = () => {
    if (groupType === "new") return navigate("/");
    return navigate(-1);
  };

  const isOwner = isAuth && userId === data?.data?.author.creatorId;
  const isLimit = (isAuth && userInfo?.isLimit) || false;

  const actionButtonSlot = () => {
    if (isOwner || data?.data?.isMember)
      return (
        <Button className="w-full" onClick={() => navigate(`/chat/${groupId}`)}>
          Chat
        </Button>
      );

    return (
      <Button
        className="w-full"
        data-umami-event="Join Group Button"
        onClick={
          isAuth
            ? isLimit
              ? () => setIsOpenPremiumModal(true)
              : () => onJoinGroup()
            : () => {
                window.localStorage.setItem(
                  "returnTo",
                  btoa(window.location.href)
                );
                navigate(`/login?returnTo=${btoa(`/group/${groupId}`)}`);
              }
        }
      >
        Join Group
      </Button>
    );
  };

  const renderCreator = () => {
    if (!isAuth) {
      return (
        <Link
          to={`/login?returnTo=${btoa(`/group/${groupId}`)}`}
          className="flex items-center gap-[10px] py-[6px] bg-gray-100 rounded-lg p-2"
        >
          <div
            className="w-[30px] h-[30px] rounded-full bg-cover bg-center"
            style={{
              backgroundImage: `url(${data?.data?.author.image})`,
            }}
          />
          <div className="font-semibold">{data?.data?.author.name}</div>
        </Link>
      );
    }

    return (
      <Link
        to={`/profile/${data?.data?.author?.creatorId}`}
        className="flex items-center gap-[10px] py-[6px] bg-gray-100 rounded-lg p-2"
      >
        <div
          className="w-[30px] h-[30px] rounded-full bg-cover bg-center"
          style={{
            backgroundImage: `url(${data?.data?.author.image})`,
          }}
        />
        <div className="font-semibold">{data?.data?.author.name}</div>
      </Link>
    );
  };

  if (isLoading) return <SkeletonGroup />;

  return (
    <>
      <Layout
        actionButtonSlot={isTiktokView ? null : actionButtonSlot()}
        className="relative"
      >
        <div
          onClick={onClickBack}
          className="absolute top-4 left-4 bg-white border rounded-lg border-gray-200 p-1 cursor-pointer z-30"
        >
          <ArrowLeft className="size-[26px]" />
        </div>
        <div className="w-full">
          <div className="relative w-full">
            <img
              src={`${data?.data?.images?.[0]}`}
              className="h-[350px] bg-gray-800 object-cover w-full"
            />
            <div className="absolute inset-x-0 bottom-0 h-[50%] bg-gradient-to-t from-black/80 to-transparent" />
            {/* <div className="absolute bottom-16 left-0 right-0 mx-auto w-full flex justify-center">
              <div className="font-semibold text-[30px] text-white text-center px-2 w-full">
                {`${data?.data?.title}`}
              </div>
            </div> */}
          </div>
          <Container className="relative -mt-10 bg-white rounded-t-[30px] px-4">
            <div className="w-full flex justify-center py-2">
              <div className="w-20 h-1 bg-gray-300 rounded-full"></div>
            </div>
            <div className="w-full pt-2">
              <div className="font-semibold text-[24px]">
                {data?.data?.title}
              </div>
              <div className="flex items-center justify-between mt-2">
                <div className="flex items-center gap-1">
                  <Calendar className="size-[14px] min-w-[14px] text-third" />
                  <div className="text-[14px] text-third">
                    {`${formatDate(data?.data?.startDate)} - ${formatDate(
                      data?.data?.endDate
                    )}`}
                  </div>
                </div>
                {!isTiktokView && (
                  <div className="flex items-center gap-1">
                    <Accounts className="size-[14px] min-w-[14px] text-third" />
                    <div className="text-[14px] text-third">
                      {data?.data?.membersCount} members
                    </div>
                  </div>
                )}
              </div>
              {countriesMap[data?.data?.country] ? (
                <div className="flex items-center gap-1">
                  <div className="text-[14px] text-third">{`${
                    countriesMap[data?.data?.country].emoji
                  } ${countriesMap[data?.data?.country].name}`}</div>
                </div>
              ) : null}
              {isTiktokView && <UserAvatars users={users?.data || []} />}
              <div className="py-4">
                {isTiktokView ? actionButtonSlot() : renderCreator()}
              </div>
              <div className="text-[14px] pt-2 whitespace-pre-wrap">
                <div className="font-semibold text-[16px]">About Group</div>
                <div className="text-[14px] pt-1">
                  {data?.data?.description?.replace(/\n/g, "\n") || ""}
                </div>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
      <PremiumModal
        isOpen={isOpenPremiumModal}
        onClose={() => setIsOpenPremiumModal(false)}
      />
    </>
  );
};
