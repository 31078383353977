import React, { useState, useEffect } from "react";

export const PhotoInput = ({
  onChange,
  id,
  placeholder,
  maxSize = 5 * 1024 * 1024, // 5MB default max size
  value,
}) => {
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (value) {
      setPreview(value);
    }
  }, [value]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      if (file.size <= maxSize) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview(reader.result);
        };
        reader.readAsDataURL(file);
        onChange(file);
      } else {
        alert(`File size exceeds ${maxSize / 1024 / 1024}MB limit.`);
      }
    } else {
      alert("Please select a valid image file.");
    }
  };

  return (
    <div className="relative h-80">
      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        id={id}
        className="hidden"
      />
      <label
        htmlFor={id}
        className="flex items-center justify-center w-full h-full bg-white cursor-pointer overflow-hidden"
      >
        {preview ? (
          <img
            src={
              typeof preview === "string"
                ? preview
                : URL.createObjectURL(preview)
            }
            alt="Preview"
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="text-center">
            <p>{placeholder || "Click to upload photo"}</p>
            <p className="text-sm text-gray-500">or drag and drop</p>
          </div>
        )}
      </label>
    </div>
  );
};
